<fw-dialog width="small" [showClose]="true" icon="data-tree" [title]="'Update Tenant'">

  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content" [formGroup]="editTenantForm">
      <fw-alert
        *ngIf="errorMessage" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true"
        (close)="handleAlertClose()">
        {{ errorMessage }}
      </fw-alert>
      <fw-grid>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-form-heading title="Name"></fw-form-heading>
          <fw-text-input
            placeholder="Tenant Name"
            [maxLength]="127"
            formControlName="label"
            [errorText]="getErrorMessage('label')"></fw-text-input>
        </fw-grid-item>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-form-heading title="Release Channel"></fw-form-heading>
          <fw-select
            *ngIf="releaseChannels"
            [options]="releaseChannels"
            placeholder="Select Channel..."
            width="260px"
            formControlName="release_channel"
            valueProperty="name"
            titleProperty="name"
          >
          </fw-select>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-form-heading title="Notes"></fw-form-heading>
          <fw-textarea-input
            placeholder="Notes"
            formControlName="notes"
            [minRows]="7" [maxRows]="10" [maxlength]="1000"
            [error]="editTenantForm.controls.notes.invalid"
            [errorText]="getErrorMessage('notes')"></fw-textarea-input>
        </fw-grid-item>
      </fw-grid>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <fw-button variant="outline" color="danger" (click)="verifyDeactivation()" [testId]="'btn-verify-deactivation'" [disabled]="data?.deployment_status!=='deployed'">Deactivate</fw-button>
    <div class="flex"></div>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-edit-tenant-cancel'">Cancel</fw-button>
    <fw-button
      (click)="updateTenant()" [testId]="'btn-edit-tenant-send'"
      [disabled]="busy || editTenantForm.invalid || !editTenantForm.dirty || data?.deployment_status!=='deployed'">Update Tenant
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
