<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <h3>User Information</h3>
  </fw-dialog-header>

  <fw-dialog-content>
    <section class="avatar">
      <fw-avatar content="initial" [initial]="initials" size="x-large"></fw-avatar>
      <fw-button
        *ngIf="status==='pending' || status==='expired_invite'"
        (click)="handleResend()"
        variant="ghost"
        [disabled]="resendInviteMutation.isPending()"
        [testId]="'btn-edit-user-resend-invite'"
      >
        Resend Invite
      </fw-button>
      <fw-button
        *ngIf="status==='pending'"
        (click)="handleRevoke()"
        variant="ghost"
        color="danger"
        [testId]="'btn-edit-user-revoke-invite'"
      >
          Revoke Invite
        </fw-button>
    </section>

    <section class="info">
      <ng-container [ngSwitch]="status">
        <fw-chip color="green" *ngSwitchCase="'active'" title="Active"></fw-chip>
        <fw-chip color="orange" *ngSwitchCase="'expired_invite'" title="Expired"></fw-chip>
        <fw-chip color="slate" *ngSwitchCase="'deactivated'" title="Deactivated"></fw-chip>
        <fw-chip color="secondary" *ngSwitchCase="'pending'" title="Pending Invite"></fw-chip>
        <fw-chip color="red" *ngSwitchCase="'cancelled'" title="Cancelled"></fw-chip>
      </ng-container>

      <div>
        <h3>Name</h3>
        <p>{{fullName || '...'}}</p>
      </div>

      <div>
        <h3>Email</h3>
        <p>{{email || '...'}}</p>
      </div>

      <div>
        <h3>Role</h3>
        <p>{{role || '...'}}</p>
      </div>

      <div>
        <h3>User ID</h3>
        <p>{{userId || '...'}}</p>
      </div>
    </section>
  </fw-dialog-content>

  <fw-dialog-actions>
    <fw-button
      (click)="handleDeactivate()"
      [disabled]="updateUserMutation.isPending()"
      *ngIf="status==='active' && canEditUsers() && authService.currentUser?.id !== userId"
      variant="outline"
      color="danger"
      leftIcon="close-circled"
      [testId]="'btn-edit-user-deactivate'"
    >
      Deactivate User
    </fw-button>
    <fw-button
      (click)="handleReactivate()"
      [disabled]="updateUserMutation.isPending()"
      *ngIf="status==='deactivated' && canEditUsers()"
      variant="outline" color="danger"
      leftIcon="done-check"
      [testId]="'btn-edit-user-reactivate'"
    >
      Reactivate User
    </fw-button>
    <div class="flex-space"></div>
      <fw-button (click)="close()" variant="outline" [testId]="'btn-edit-user-cancel'">Cancel</fw-button>
      <fw-button
        (click)="handleEdit()"
        [disabled]="updateUserMutation.isPending()"
        *ngIf="canEditUsers() && authService.currentUser?.id !== userId"
        [testId]="'btn-edit-user-edit'"
      >
        Edit User Information
      </fw-button>
  </fw-dialog-actions>
</fw-dialog>
