import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FwDialogService } from '@flywheel-io/vision';

import { PermissionService } from '../../services/permission.service';
import { NewUserDialogComponent } from './new-user-dialog/new-user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {

  constructor(
    private permissions: PermissionService,
    public dialog: FwDialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  canAddUsers = this.permissions.check('CREATE_ORG_USER');

  // needs to be an arrow function to fix scoping issues around `this` when it's called
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goBack = (): any => {
    const hasHistory = window.history.length > 1;
    const lastPageWasThisSite = window.history.state.navigationId > 1;

    if(hasHistory && lastPageWasThisSite) {
      window.history.back();
    }
    else {
      this.router.navigate(['..', 'dashboard'], { relativeTo: this.route });
    }
  }

  openNewUserDialog(): void {
    if (this.canAddUsers()) {
      this.dialog.openDialog(NewUserDialogComponent, {
        data: {},
      });
    }
  }

}
