<app-theme [wrapped]="true">
  <div class="page-wrapper">
    <fw-section-heading title="Billing" [backButton]="true" [backFunction]="goBack">
      <fw-chip title="Preview" color="primary"></fw-chip>
      <a [download]="getCsvName()" [href]="getCsvUrl()">
        <fw-button leftIcon="document-file-download" variant="outline" [disabled]="billingQuery.isLoading() || noData()">Export</fw-button>
      </a>
    </fw-section-heading>
    <app-billing-filters (filterChange)="onFilterChange($event)"></app-billing-filters>
    <div *ngIf="!noData() else noDataDisplay" class="content-wrapper">
      <div *ngIf="billingQuery.isFetching()" id="loading-overlay">
        <fw-progress-spinner></fw-progress-spinner>
      </div>
      <section class="overall">
        <h1>{{formatDate(filters().range.start, "MMMM d, yyyy")}} - {{formatDate(filters().range.end, "MMMM d, yyyy")}}</h1>
        <div class="overall-info">
          <app-cost-card
            [highlighted]="true"
            title="Total Cost"
            icon="invoice"
            [cost]="totalCost()"
          ></app-cost-card>
          <app-proportional-cost [proportions]="proportionalCosts()"></app-proportional-cost>
        </div>
      </section>
      <hr>
      <section class="summary">
        <h1>Summary</h1>
        <div class="cost-cards">
          <app-cost-card
            title="Storage"
            icon="server"
            [cost]="totalsByCategory()?.Storage"
          ></app-cost-card>
          <app-cost-card
            title="Compute"
            icon="settings-gear"
            iconColor="secondary"
            [cost]="totalsByCategory()?.Compute"
          ></app-cost-card>
          <app-cost-card
            title="Other"
            icon="servers-database"
            iconColor="green"
            [cost]="totalsByCategory()?.Other"
          ></app-cost-card>
        </div>
        <app-billing-chart
          [series]="series()"
          [categories]="timeGroups()"
        ></app-billing-chart>
      </section>
    </div>
    <ng-template #noDataDisplay>
      <fw-layout-context
        *ngIf="!filters()?.dirty"
        id="no-data-display"
        width="large"
        icon="search-loop"
        iconColor="secondary"
        title="No Data Found"
        description="We don’t have any data to display. Check back later or try expanding your search for historical data."
      ></fw-layout-context>
      <fw-layout-context
        *ngIf="filters()?.dirty"
        id="no-data-display"
        width="large"
        icon="search-loop"
        iconColor="secondary"
        title="No Data Found"
        description="No data matches your filters. Clear filters and try again."
      >
        <fw-button variant="outline" (click)="resetFilters()">Clear Filters</fw-button>
      </fw-layout-context>
    </ng-template>
  </div>
</app-theme>
