import { format } from 'date-fns';

import { TimeMode } from '../billing.types';


export const customTooltip = (timeMode: TimeMode) => {

  // Apex doesn't even type this function internally so we need an 'any' here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ({ series, seriesIndex, dataPointIndex, w }: any): string => {
    const category = w.globals.seriesNames[seriesIndex];
    const date = new Date(Date.parse(w.globals.labels[dataPointIndex]));

    const dateFormatterFnMap: { [key: string ]: (date: string | number | Date) => string} = {
      'year': (date) => format(date, 'yyyy'),
      'month': (date) => format(date, 'LLLL, yyyy'),
      'day': (date) => format(date, 'LLLL d, yyyy'),
    }

    const formattedDate = dateFormatterFnMap[timeMode](date);
    const cost = '$' + series[seriesIndex][dataPointIndex].toLocaleString();

    // we need to have the styles be internal to the tooltip as we have other way
    // to have them in scope when it's injected
    return `
      <style>
        .chart-tooltip {
          border: 1px solid var(--separations-base)
          box-shadow: 0px 5px 15px 0px #0000001A;
          background-color: var(--page-light);
          padding: 8px 16px;
        }

        .chart-tooltip > h2 {
          color: var(--typography-base);
          font-family: Inter;
          font-size: 18px;
          font-weight: 500;
          line-height: 21.78px;
          text-align: left;
          margin: 8px 0 0 0;
        }

        .chart-tooltip > h3 {
          color: var(--typography-light);
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          text-align: left;
          margin: 0;
        }

        .chart-tooltip > h4 {
          color: var(--typography-muted);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          text-align: left;
          margin: 0;
        }
      </style>
      <div class="chart-tooltip">
        <h3>${category}</h3>
        <h4>${formattedDate}</h4>
        <h2>${cost}</h2>
      </div>
    `;
  }
}
