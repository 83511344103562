<section class="filters" [formGroup]="filteringForm">
  <div class="filter-group">
    <div>
      <fw-form-heading title="Tenant"></fw-form-heading>
      <fw-select
        placeholder="Filter by tenant"
        formControlName="tenantIds"
        titleProperty="label"
        valueProperty="id"
        [options]="tenantOptions"
        [showReset]="true"
        maxOptionsHeight="400px"
        [showFilter]="tenantOptions.length > 9"
      ></fw-select>
    </div>
  </div>
  <div class="filter-group">
    <div>
      <fw-form-heading title="Filter by Time"></fw-form-heading>
      <fw-select formControlName="namedRange">
        <fw-menu-item title="Current Month" value="thisMonth"></fw-menu-item>
        <fw-menu-item title="Last Month" value="lastMonth"></fw-menu-item>
        <fw-menu-separator></fw-menu-separator>
        <fw-menu-item title="Yesterday" value="yesterday"></fw-menu-item>
        <fw-menu-item title="Last Week" value="lastWeek"></fw-menu-item>
        <fw-menu-item title="Year to Date" value="yearToDate"></fw-menu-item>
        <!-- Last year is outside of the range we have data for, for now <fw-menu-item title="Last Year" value="lastYear"></fw-menu-item> -->
        <fw-menu-item title="All Time" value="allTime"></fw-menu-item>
        <fw-menu-item title="Custom" value="custom"></fw-menu-item>
      </fw-select>
    </div>
    <div formGroupName="range">
      <fw-form-heading title="Start Date"></fw-form-heading>
      <fw-date-input
        formControlName="start"
        [min]="formatForDatePicker(billingEpoch)"
        [max]="filteringForm.controls.range.controls.end.value || undefined"
      ></fw-date-input>
    </div>
    <div formGroupName="range">
      <fw-form-heading title="End Date"></fw-form-heading>
      <fw-date-input
        formControlName="end"
        [min]="filteringForm.controls.range.controls.start.value || undefined"
        [max]="formatForDatePicker(yesterday)"
      ></fw-date-input>
    </div>
  </div>
</section>
