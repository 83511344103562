<app-theme [fitted]="true">
  <fw-layout-context
    width="medium"
    icon="user-delete-cross"
    iconColor="secondary"
    title="403 Forbidden"
    description="Looks like you are not set up to be able to view this page. If you believe this is an error, contact your Org Admin to verify permissions."
  >
    <fw-button routerLink="/" variant="outline">Return to Home</fw-button>
  </fw-layout-context>
</app-theme>
