import { HttpClient } from '@angular/common/http';
import { computed, Injectable, Signal } from '@angular/core';
import { CreateQueryResult, injectQuery } from '@tanstack/angular-query-experimental';
import { format } from 'date-fns';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ActiveOrgService } from '../../services/active-org/active-org.service';
import { buildSearchParams } from '../../services/service-utils';
import { BillingFilterState,BillingLineItem } from './billing.types';

@Injectable({
  providedIn: 'root',
})
export class BillingService {

  constructor(
    private http: HttpClient,
    private activeOrgService: ActiveOrgService,
  ) {}

  getBillingData(orgId: string, options: BillingFilterState): Observable<BillingLineItem[]> {

    let optionsQueryParams = '';

    if (options) {
      const optionsReshaped = {
        // conditionally add tenant
        ...(options.tenantId) && { tenant_ids: [options.tenantId] },
        start_date: format(options.range.start, 'yyyy-MM-dd'),
        end_date: format(options.range.end, 'yyyy-MM-dd'),
      };

      optionsQueryParams = buildSearchParams(optionsReshaped);
    }

    return this.http.get<BillingLineItem[]>(
      `${environment.apiBaseUrl}/organizations/${orgId}/billing?${optionsQueryParams}`,
    );
  }

  injectBillingQuery(options: Signal<BillingFilterState>, orgId?: string): CreateQueryResult<BillingLineItem[], Error> {

    const orgIdToFetch = computed(() => {
      const activeOrg = this.activeOrgService.org();
      return orgId || activeOrg?.organization_id || '';
    })

    const oneHourInMs = 1 * 60 * 60 * 1000 // hour * minutes * seconds * ms

    return injectQuery(() => ({
      queryKey: ['billing', orgIdToFetch(), options()],
      staleTime: oneHourInMs,
      queryFn: () => {
        return lastValueFrom(this.getBillingData(orgIdToFetch(), options()));
      },
    }));
  }
}
