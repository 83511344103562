import { Component, computed, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  closestIndexTo,
  differenceInDays,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  isSameYear,
  startOfMonth,
  subDays,
} from 'date-fns';
import { ApexAxisChartSeries } from 'ng-apexcharts';

import { BillingService } from './billing.service';
import { BillingDisplayCategory, BillingFilterState, BillingLineItem, categoryToDisplayCategory, CategoryTotals, TimeGroups, TimeMode } from './billing.types';
import { BillingFiltersComponent } from './billing-filters/billing-filters.component';

type TimeBucket = {
  date: Date,
  lineItems: BillingLineItem[],
  categories: CategoryTotals
}

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent {
  @ViewChild(BillingFiltersComponent) filtersComponent?: BillingFiltersComponent;
  billingQuery;
  billingData;

  constructor(
    private billingService: BillingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.billingQuery = this.billingService.injectBillingQuery(this.filters);
    this.billingData = this.billingQuery.data;
  }

  onFilterChange(change: BillingFilterState): void {
    this.filters.set(change);
  }

  // needs to be an arrow function to fix scoping issues around `this` when it's called
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goBack = (): any => {
    const hasHistory = window.history.length > 1;
    const lastPageWasThisSite = window.history.state.navigationId > 1;

    if(hasHistory && lastPageWasThisSite) {
      window.history.back();
    }
    else {
      this.router.navigate(['..', 'dashboard'], { relativeTo: this.route });
    }
  }

  getCsvUrl = computed(() => {
    const lineItems = this.billingData();

    if (!lineItems || lineItems.length === 0) {
      return;
    }

    const csvLines: string[] = [];
    const titleRow = Object.keys(lineItems[0]).join(',');
    csvLines.push(titleRow);

    const sortedLineItems = lineItems.sort((lineA, lineB) => {
      // sort by date
      if(isBefore(lineA.date, lineB.date)) {
        return -1;
      }
      else if (isAfter(lineA.date, lineB.date)) {
        return 1;
      }

      // secondary sort by tenant path
      if (lineA.tenant_path < lineB.tenant_path) {
        return -1;
      }
      else if (lineA.tenant_path > lineB.tenant_path) {
        return 1;
      }

      // tertiary sort by category
      if(lineA.category < lineB.category) {
        return -1;
      }
      else if(lineB.category < lineA.category) {
        return 1
      }

      return 0;
    })

    const valueRows = sortedLineItems.map(lineItem => {
      const dateString = format(lineItem.date, 'MM/dd/yyyy');
      return Object.values({
        ...lineItem,
        date: dateString,
      }).join(',');
    });
    csvLines.push(...valueRows);

    const fullCSV = csvLines?.join('\n');
    const blob = new Blob([fullCSV], { type: 'text/csv;charset=utf-8' });
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  });

  getCsvName = computed(() => {
    const filters = this.filters();

    const startString = format(filters.range.start, 'MM-dd-yyyy');
    const endString = format(filters.range.end, 'MM-dd-yyyy');
    const dateString = startString + '-' + endString;
    const tenantString = filters.tenantId ? '_tenants_' + filters.tenantId : '';

    return `flywheel_billing_${dateString}${tenantString}.csv`;
  })


  // bind for the template
  formatDate = format;

  filters = signal<BillingFilterState>({
    range: {
      start: startOfMonth(new Date()),
      end: subDays(new Date(), 1), // yesterday
    },
    tenantId: '',
    dirty: false,
  });

  resetFilters():void {
    this.filtersComponent?.resetFilters();
  }

  noData = computed(() => {
    return !this.billingQuery.isPending() && this.billingData()?.length === 0;
  })

  totalsByCategory = computed(() => {
    const categories: CategoryTotals = {
      Compute: 0,
      Storage: 0,
      Other: 0,
    }

    return this.billingData()?.reduce<typeof categories>(
      (totals, current) => {
        const displayCategory = categoryToDisplayCategory(current.category);
        totals[displayCategory] += current.total_cost;
        return totals;
      },
      categories,
    );
  })

  proportionalCosts = computed(() => {
    const categoryTotals = this.totalsByCategory();
    const totalCost = this.totalCost();

    // portions withe percentage as an integer
    // start with everything even
    const portions: CategoryTotals = {
      Compute: 34,
      Storage: 33,
      Other: 33,
    }

    if (!categoryTotals || !totalCost) {
      return portions
    }

    const calculatedPortions: Record<BillingDisplayCategory, number> = {
      Compute: Math.floor((categoryTotals.Compute / totalCost) * 100),
      Storage: Math.floor(categoryTotals.Storage / totalCost * 100),
      Other: Math.floor((categoryTotals.Other / totalCost) * 100),
    }

    const categories = Object.keys(calculatedPortions) as BillingDisplayCategory[]

    // there's a chance that with the rounding we don't add up to a hundred percent,
    // this figures out how much space we have left and adds the diff to the largest category
    const totalPercentage = categories.reduce((acc, key) => (acc + calculatedPortions[key]), 0);
    const diffToHundred = 100 - totalPercentage;

    if(diffToHundred) {
      const highestPortion = Math.max(...categories.map(cat => calculatedPortions[cat]));
      const highestCategory = categories.find(cat => calculatedPortions[cat] === highestPortion);

      if(highestCategory) {
        calculatedPortions[highestCategory] += diffToHundred;
      }
    }

    return calculatedPortions;
  })

  totalCost = computed(() => {
    return this.billingData()?.reduce<number>((accumulator, current) => (accumulator + current.total_cost), 0);
  })

  timeGroups = computed<TimeGroups>(() => {

    const { range } = this.filters();

    const groupByMonths = Math.abs(differenceInDays(range.start, range.end)) > 50;
    const groupByYears = Math.abs(differenceInYears(range.start, range.end)) >= 2;

    if (groupByYears) {
      return {
        groups: eachYearOfInterval(range),
        mode: 'year',
      }
    }

    if (groupByMonths) {
      return {
        groups: eachMonthOfInterval(range),
        mode: 'month',
      }
    }

    // default to grouping by days
    return {
      groups: eachDayOfInterval(this.filters().range),
      mode: 'day',
    }
  })

  series = computed<ApexAxisChartSeries>(() => {

    // make sure we have data
    const range = this.timeGroups();
    const bills = this.billingData();

    if (!bills || !range) {
      return [];
    }

    // create buckets for each unit of time in our series
    const billsGroupedByTime: TimeBucket[] = range.groups.map(day => {
      return {
        date: day,
        lineItems: [],
        categories: {
          Compute: 0,
          Storage: 0,
          Other: 0,
        },
      }
    })

    // sort each lineItem into the correct time group
    for (const lineItem of bills) {

      const findTimeBucketIndex = (lineItem: BillingLineItem, groups: Date[], mode: TimeMode): number | undefined => {
        const findIndexFunctionMap = {
          'day': () => closestIndexTo(lineItem.date, groups),
          'month': () => range.groups.findIndex(group => isSameMonth(group, lineItem.date)),
          'year': () => range.groups.findIndex(group => isSameYear(group, lineItem.date)),
        }
        return findIndexFunctionMap[mode]();
      }

      const timeBucketIndex = findTimeBucketIndex(lineItem, range.groups, range.mode);

      // need to explicitly check undefined as 0 is valid
      if (timeBucketIndex !== undefined) {
        // push the line item and add up total by category
        const correctBucket = billsGroupedByTime[timeBucketIndex];
        correctBucket.lineItems.push(lineItem);
        const displayCategory = categoryToDisplayCategory(lineItem.category);

        correctBucket.categories[displayCategory] += lineItem.total_cost;
      }
    }

    // there's probably a way to do this with less iterations, but this is simple
    // map 0s to null as that hides the border for empty data points on the chart
    const storageSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Storage) || null);
    const computeSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Compute) || null);
    const otherSeries = billsGroupedByTime.map(day => Math.floor(day.categories.Other) || null)

    const series: ApexAxisChartSeries = [
      {
        name: 'Storage',
        data: storageSeries,
      },
      {
        name: 'Compute',
        data: computeSeries,
      },
      {
        name: 'Other',
        data: otherSeries,
      },
    ]

    return series;
  })
}
