import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent {
  @Input() layout: string = 'central';
  @Input() page: 'light' | 'shaded' = 'light';
  @Input() wrapped: boolean = false;
  @Input() fitted: boolean = false;

  constructor() {}
}
