import { Injectable } from '@angular/core';

export type Theme = 'vision-light-theme' | 'vision-dark-theme';
export type ThemePreference = Theme | 'match-browser';

const LOCAL_STORAGE_THEME = 'flywheel-theme-preference';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {


  private _theme: Theme = 'vision-light-theme';
  public themePreference: ThemePreference = 'vision-light-theme';

  constructor() {
    // local preference takes priority as it is set explicitly
    const localPreference = localStorage.getItem(LOCAL_STORAGE_THEME) as ThemePreference | undefined;
    this.setTheme(localPreference);
  }


  setTheme(pref?: ThemePreference): void {
    this.themePreference = pref || 'match-browser';
    const theme = this.preferenceToTheme(pref);

    localStorage.setItem(LOCAL_STORAGE_THEME, this.themePreference);
    this.updateClasses(theme);
    this._theme = theme;
  }

  get theme(): Theme {
    return this._theme;
  }

  private updateClasses(theme: Theme): void {
    document.body.classList.remove('vision-dark-theme', 'vision-light-theme');
    document.body.classList.add(theme);
  }

  private preferenceToTheme(pref?: ThemePreference): Theme {
    if(!pref || pref === 'match-browser') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'vision-dark-theme' : 'vision-light-theme';
      return systemTheme;
    }

    return pref;
  }
}
