import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, filter, of, switchMap, tap } from 'rxjs';

import { Permission } from '../models/organization';
import { ExpandedUser } from '../models/users.types';
import { PermissionService } from '../services/permission.service';
import { SelfService } from '../services/self/self.service';
import { getOrgSlug } from './active-org.guard';

/**
 * Checks that the current user has the passed permission(s)
 *
 * On Failure: Redirects to 403 page
 */
export const permissionGuard = (permissions: Permission | Permission[]): CanActivateFn => {
  return (route) => {
    const router = inject(Router);
    const permissionService = inject(PermissionService);
    const selfService = inject(SelfService);
    const self$ = toObservable(selfService.injectSelfQuery().data);

    return self$.pipe(
      // filter out initial undefined emission
      filter((self): self is ExpandedUser => Boolean(self)),
      switchMap((self) => {

        const relevantOrg = self.organizations?.find(org => getOrgSlug(org) === route.params['organization']);

        const hasOrgScopedPermission = permissionService.check(permissions, relevantOrg?.organization_id);
        const hasPlatformPermission = permissionService.checkPlatformPermission(permissions);

        return of(hasOrgScopedPermission() || hasPlatformPermission());
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          throw new Error('User doesn\'t have required permissions');
        }
      }),
      catchError(() => {
        router.navigate(['/permission-failure']).then(() => {});
        return of(false);
      }),
    );
  };
};

/**
 * Checks that the current user has the passed permission(s)
 *
 * On Failure: Redirects to 403 page
 */
export const platformPermissionGuard = (permissions: Permission | Permission[]): CanActivateFn => {
  return () => {
    const router = inject(Router);
    const permissionService = inject(PermissionService);
    const selfService = inject(SelfService);
    const self$ = toObservable(selfService.injectSelfQuery().data);

    return self$.pipe(
      // filter out initial undefined emission
      filter((self): self is ExpandedUser => Boolean(self)),
      switchMap(() => {
        const hasPlatformPermission = permissionService.checkPlatformPermission(permissions);

        return of(hasPlatformPermission());
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          throw new Error('User doesn\'t have required permissions');
        }
      }),
      catchError(() => {
        router.navigate(['/permission-failure']).then(() => {});
        return of(false);
      }),
    );
  };
};
