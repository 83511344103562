import { HttpClient } from '@angular/common/http';
import { Injectable, Signal } from '@angular/core';
import { CreateQueryResult, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { FeedStreamItems, FeedStreamRequestOptions } from '../../models/stream.types';
import { buildSearchParams } from '../service-utils';

@Injectable({
  providedIn: 'root',
})
export class FeedStreamsService {
  client;

  constructor(
    private http: HttpClient,
  ) {
    this.client = injectQueryClient();
  }


  /**
  *
  * @param options details on options can be found in the [docs](https://feed.dev.cen.flywheel.io/docs)
  * @returns stream items from Uberflip
  */
  getFeedStreamItems(streamId: number, options: FeedStreamRequestOptions): Observable<FeedStreamItems> {
    return this.http.get<FeedStreamItems>(`${environment.apiFeedUrl}/streams/${streamId}/items?${buildSearchParams(options)}`);
  }

  injectFeedStreamItemsQuery(options: Signal<FeedStreamRequestOptions>, id?: number): CreateQueryResult<FeedStreamItems, Error> {
    const streamId: number = id || 11613169;

    return injectQuery(() => ({
      queryKey: ['newsFeed', streamId, options()],
      queryFn: () => {
        return lastValueFrom(this.getFeedStreamItems(streamId, options()))
      },
    }))
  }
}
