import { Component, computed } from '@angular/core';

import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent {


  constructor(private orgsService: OrganizationsService) {

  }

  orgs = this.orgsService.injectAllOrganizationsQuery().data;
  orgCount = computed(() => this.orgs()?.length || 0);
}
