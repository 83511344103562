import { CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  FwAppIconModule,
  FwAvatarModule,
  FwBreadcrumbsModule,
  FwButtonModule,
  FwChipModule,
  FwDialogsModule,
  FwIconButtonModule,
  FwIconModule,
  FwLayoutsModule,
  FwMenuModule,
  FwSelectMenuModule,
  FwSnackbarModule,
  FwTextInputModule } from '@flywheel-io/vision';

import { TestIdDirective } from '../directives/test-id.directive';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { ThemeComponent } from './theme.component';
import { EditProfileComponent } from './toolbar/edit-profile/edit-profile.component';
import { ProfileDialogComponent } from './toolbar/profile-dialog/profile-dialog.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { WordmarkComponent } from './wordmark/wordmark.component';

@NgModule({
  imports: [
    CdkMenuTrigger,
    FwChipModule,
    CommonModule,
    FwAppIconModule,
    FwAvatarModule,
    FwBreadcrumbsModule,
    FwDialogsModule,
    FwIconButtonModule,
    FwIconModule,
    FwButtonModule,
    FwLayoutsModule,
    FwTextInputModule,
    FwMenuModule,
    FwSelectMenuModule,
    FwSnackbarModule,
    RouterLink,
    RouterLinkActive,
    TestIdDirective,
    ReactiveFormsModule,
  ],
  declarations: [
    HeaderComponent,
    LoaderComponent,
    ThemeComponent,
    ToolbarComponent,
    WordmarkComponent,
    ProfileDialogComponent,
    EditProfileComponent,
  ],
  exports: [
    HeaderComponent,
    LoaderComponent,
    ThemeComponent,
    ToolbarComponent,
    WordmarkComponent,
  ],
})
export class ThemeModule {
}
