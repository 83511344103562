<ng-template #content>
    <ng-content></ng-content>
</ng-template>
<div class="page" [ngClass]="'page-'+page" *ngIf="wrapped">
    <div class="app-layout-central" *ngIf="layout==='central'">
        <app-header></app-header>
        <div class="header-page">
            <ng-container *ngTemplateOutlet='content'></ng-container>
        </div>
        <fw-snackbar-container></fw-snackbar-container>
    </div>
</div>
<div class="page" [ngClass]="['page-'+page, fitted?'fitted':'']" *ngIf="!wrapped">
  <ng-container *ngTemplateOutlet='content'></ng-container>
</div>
