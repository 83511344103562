import pkg from '../../package.json';

export const environment = {
  production: false,
  devTools: false,
  name: 'dev',
  sentry_dsn: 'https://9f2548143c235960190bbc2b49111910@o347616.ingest.us.sentry.io/4507074137423872',
  package_name: pkg.name,
  package_version: pkg.version,
  apiBaseUrl: 'https://manager.dev.cen.flywheel.io',
  apiFeedUrl: 'https://feed.dev.cen.flywheel.io',
  tenantBaseUrl: '.dev.ten.flywheel.io',
  auth: {
    domain: 'auth.dev.cen.flywheel.io',
    cookieDomain: '.flywheel.io',
    clientId: 'X6ZZsXe9RhteBS1y3VL2qAXu9UrEc0TO',
    authorizationParams: {
      audience: 'https://central-management-dev',
      redirect_uri: `${window.location.origin}/callback`,
      scope: 'openid profile email offline_access',
    },
  },
};
