/* eslint-disable @typescript-eslint/naming-convention */
import { Organization, Permission } from './organization';

export interface User {
  id?: string;
  email: string;
  given_name: string;
  family_name: string;
  auth0id?: string;
  revision?: number;
  organizations: Organization[];
  platform_role: PlatformRole;
  platform_role_permissions: Permission[];
}


export interface ExpandedUser extends User {
  fullName: string;
  initials: string;
  role: string;
  organizationName: string;
}

export interface OrgUser {
  user_id: string,
  email: string,
  given_name: string,
  family_name: string,
  organization_role: OrgRole,
  job_title: string[],
  revision: number,
  status: UserStatus,
  created: Date,
}

export interface Paginated<T> {
  items: T[];
  next_cursor: null;
  previous_cursor: null;
  total: number;
}

export interface UsersRequestOptions {
  size: number;
  cursor?: string | null;
  filter?: string;
  org_roles?: string[];
  statuses?: string[];
}

export type UserStatus = 'active' | 'pending' | 'deactivated' | 'expired_invite' | 'cancelled';

export type PlatformRole = 'ADMIN' | 'MEMBER';
export type OrgRole = 'STAFF_ADMIN' | 'ORG_ADMIN' | 'DEVELOPER' | 'MEMBER';

export const orgRoleNameMap: { [k in OrgRole]: string } = {
  'STAFF_ADMIN': 'Staff Admin',
  'ORG_ADMIN': 'Admin',
  'DEVELOPER': 'Developer',
  'MEMBER': 'Member',
};

export const orgRoleOptions = [
  { value: 'MEMBER', title: 'Member' },
  { value: 'DEVELOPER', title: 'Developer' },
  { value: 'ORG_ADMIN', title: 'Admin' },
];

export type VerifyInviteResponse = {
  user_id: string;
  organization_id: string;
  email: string;
  given_name: string;
  family_name: string;
  organization_role: OrgRole;
  job_titles: string[] | null;
  auth0id: string;
  membership_revision: number;
  user_revision: number;
  status: UserStatus;
  created: string; // 2024-04-25T15:17:08.078235Z
  organization: Organization,
}
