<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
    <h3>Add Organization</h3>
  </fw-dialog-header>
  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content" [formGroup]="newOrganizationForm">
      <fw-alert
        *ngIf="apiErrorMessage" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true"
        (close)="handleAlertClose()">
        {{ apiErrorMessage }}
      </fw-alert>
      <fw-layout-panel border="all">
        <fw-form-heading title="Organization" description="Name your organization and add a descriptive label to aid admins in identifying it."></fw-form-heading>
        <div class="flex">
          <fw-wrapped-input title="Name" [errorText]="getErrorMessage(newOrganizationForm.controls.name)">
            <fw-text-input placeholder="Enter organization name..." formControlName="name"></fw-text-input>
          </fw-wrapped-input>
          <fw-wrapped-input title="Description">
            <fw-text-input placeholder="Enter a short description..." formControlName="label"></fw-text-input>
          </fw-wrapped-input>
        </div>
      </fw-layout-panel>
      <fw-layout-panel border="all">
        <fw-form-heading title="Invite User" description="Invite an user to be an admin for your organization so they can invite other users and assign roles."></fw-form-heading>
        <div class="flex">
          <fw-wrapped-input title="First Name" [errorText]="getErrorMessage(newOrganizationForm.controls.given_name)">
            <fw-text-input placeholder="Enter first name..." formControlName="given_name"></fw-text-input>
          </fw-wrapped-input>
          <fw-wrapped-input title="Last Name" [errorText]="getErrorMessage(newOrganizationForm.controls.family_name)">
            <fw-text-input placeholder="Enter last name..." formControlName="family_name"></fw-text-input>
          </fw-wrapped-input>
          <fw-wrapped-input
            title="Email"
            description="User will receive an email with instruction and be added as an Org Admin."
            [errorText]="getErrorMessage(newOrganizationForm.controls.email)"
          >
            <fw-text-input placeholder="Enter email..." formControlName="email"></fw-text-input>
          </fw-wrapped-input>
        </div>
      </fw-layout-panel>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-add-user-cancel'">Cancel</fw-button>
    <fw-button
      (click)="createOrganization()"
      [testId]="'btn-add-user-send'"
      [disabled]="insertOrgMutation.isPending() || !newOrganizationForm.valid">
      Create Organization
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
