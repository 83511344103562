import { Component, effect, EventEmitter, OnDestroy, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder } from '@angular/forms';
import { endOfMonth, endOfWeek, endOfYear, format, Interval, parse, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { Subscription } from 'rxjs';

import { Organization } from '../../../models/organization';
import { Tenant } from '../../../models/tenant';
import { ActiveOrgService } from '../../../services/active-org/active-org.service';
import { SelfService } from '../../../services/self/self.service';
import { BillingFilterState } from '../billing.types';
import { isNotFutureValidator, notBeforeValidator } from './date-validators';

@Component({
  selector: 'app-billing-filters',
  templateUrl: './billing-filters.component.html',
  styleUrls: ['./billing-filters.component.scss'],
})
export class BillingFiltersComponent implements OnDestroy {
  constructor(
    private activeOrgService: ActiveOrgService,
    private selfService: SelfService,
    private fb: FormBuilder,
  ) {
    this.listenForDateRangeChanges();
  }

  // initializing this on component creation as re-initializing it messes with diffing for the cache and causes unnecessary refreshes
  yesterday = subDays(new Date, 1);

  // the earliest that billing data may exist
  billingEpoch = new Date('01/01/2024');
  subscriptions: Subscription[] = [];
  orgOptions: Organization[] = [];
  tenantOptions: Tenant[] = [];
  @Output() filterChange = new EventEmitter<BillingFilterState>();

  filteringForm = this.fb.group({
    tenantIds: this.fb.control(''),
    namedRange: this.fb.control('thisMonth'),
    range: this.fb.group({
      start: this.fb.control<string>(this.formatForDatePicker(startOfMonth(this.yesterday))),
      end: this.fb.control<string>(this.formatForDatePicker(this.yesterday)),
    }),
    organization: this.fb.control(this.activeOrgService.org()?.name),
  });

  clearTenantFilterOnOrgChange = effect(() => {
    // need to access the active org signal to mark it as the dep of the effect
    this.activeOrgService.org();
    this.filteringForm.controls.tenantIds.reset();
  })

  listenForDateRangeChanges(): void {

    const rangeControls = this.filteringForm.controls.range;

    // we need to attach these here as it's dependent on the form being initialized
    rangeControls.controls.end.addValidators([notBeforeValidator(rangeControls.controls.start), isNotFutureValidator()]);
    rangeControls.controls.start.addValidators(notBeforeValidator(this.billingEpoch));

    // when the named range changes we should set the date pickers to the relevant range
    const namedRangeChanges$ = this.filteringForm.controls.namedRange.valueChanges.subscribe((change) => {
      if(change && change !== 'custom') {
        const chosenInterval = this.convertRangeNameToInterval(change);
        this.filteringForm.patchValue({
          range: {
            start: this.formatForDatePicker(chosenInterval.start),
            end: this.formatForDatePicker(chosenInterval.end),
          },
        }, { emitEvent: false })
      }
    });

    // when the datepickers change we should set the named range to 'custom'
    const rangeChanges$ = rangeControls.valueChanges.subscribe((change) => {

      // since we only validate the end date we need to check it even if just the start date changed
      rangeControls.controls.end.updateValueAndValidity({ emitEvent: false });
      rangeControls.markAllAsTouched();

      if(change.start && change.end) {
        this.filteringForm.patchValue({ namedRange: 'custom' }, { emitEvent: false });
      }
    });

    this.subscriptions.push(namedRangeChanges$, rangeChanges$);
  }

  convertRangeNameToInterval(label: string = ''): Interval {
    const defaultReturn = {
      start: startOfMonth(subMonths(this.yesterday, 1)),
      end: endOfMonth(subMonths(this.yesterday, 1)),
    };

    const nameToIntervalMap: { [key: string]: Interval } = {
      'thisMonth': {
        start: startOfMonth(this.yesterday),
        end: this.yesterday,
      },
      'yesterday': {
        start: this.yesterday,
        end: this.yesterday,
      },
      'lastWeek': {
        start: startOfWeek(subWeeks(this.yesterday, 1)),
        end: endOfWeek(subWeeks(this.yesterday, 1)),
      },
      'lastMonth': {
        start: startOfMonth(subMonths(this.yesterday, 1)),
        end: endOfMonth(subMonths(this.yesterday, 1)),
      },
      'yearToDate': {
        start: startOfYear(this.yesterday),
        end: this.yesterday,
      },
      'lastYear': {
        start: startOfYear(subYears(this.yesterday, 1)),
        end: endOfYear(subYears(this.yesterday, 1)),
      },
      'allTime': {
        start: this.billingEpoch,
        end: this.yesterday,
      },
    };
    return nameToIntervalMap[label] || defaultReturn;
  }

  setDropdownOptions = effect(() => {
    const self = this.selfService.selfSignal();
    const activeOrg = this.activeOrgService.org();

    if (!self) {
      this.orgOptions = [];
      return;
    }

    this.orgOptions = self.organizations.filter(org => org.status === 'active');
    this.filteringForm.controls.organization.setValue(activeOrg?.name);
    // disable until we can update active org service to have signal
    this.filteringForm.controls.organization.disable();

    const sortedTenants = this.activeOrgService.org()?.tenants.sort((a, b) => {
      if(a.label < b.label) {
        return -1;
      } else {
        return 1;
      }
    });

    // not filtering at all as they may want billing info for deactivated tenants
    this.tenantOptions = sortedTenants || [];
  }, { allowSignalWrites: true })


  formChangeSignal = toSignal(this.filteringForm.valueChanges);
  formStatusSignal = toSignal(this.filteringForm.statusChanges);

  /**
   * Boils the filter form's state down to what's needed for the API call
   */
  emitFormChanges = effect(() => {

    const status = this.formStatusSignal();
    const changes = this.formChangeSignal();

    if (status === 'INVALID') {
      return;
    }

    const formRange = changes?.range;

    if (!formRange?.start || !formRange?.end) {
      return;
    }

    const emittedRange = {
      start: parse(formRange.start, 'yyyy-MM-dd', new Date()),
      end: parse(formRange.end, 'yyyy-MM-dd', new Date()),
    }

    this.filterChange.emit({
      range: emittedRange,
      tenantId: changes?.tenantIds || '',
      dirty: this.filteringForm.dirty,
    });
  }, { allowSignalWrites: true })

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  formatForDatePicker(date?: string | number | Date): string {
    return date ? format(date, 'yyyy-MM-dd') : '';
  }

  resetFilters(): void {
    this.filteringForm.patchValue({
      namedRange: 'thisMonth',
      tenantIds: undefined,
    });

    this.filteringForm.markAsPristine();
  }
}
