import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent {
  adminOrgId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (route.snapshot && route.snapshot.params) {
      this.adminOrgId = route.snapshot.params['admin_org'];
    }
  }

  // needs to be an arrow function to fix scoping issues around `this` when it's called
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goBack = (): any => {
    const hasHistory = window.history.length > 1;
    const lastPageWasThisSite = window.history.state.navigationId > 1;

    if(hasHistory && lastPageWasThisSite) {
      window.history.back();
    }
    else {
      this.router.navigate(['..', 'dashboard'], { relativeTo: this.route });
    }
  }

}
