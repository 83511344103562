import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, computed, effect, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FwSnackbarService } from '@flywheel-io/vision';
import { Subscription } from 'rxjs';

import { OrganizationsService } from '../../../services/organizations/organizations.service';
import { TenantClustersService } from '../tenant-clusters.service';

export interface NewDialogData {
  orgId?: string;
}

@Component({
  selector: 'app-new-tenant-dialog',
  templateUrl: './new-tenant-dialog.component.html',
  styleUrls: ['./new-tenant-dialog.component.scss'],
})
export class NewTenantDialogComponent implements OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    private tenantClustersService: TenantClustersService,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: NewDialogData,
  ) {
    // watch for changes to disable the submit (couldn't get a signal approach to work)
    const statusSub = this.newTenantForm.statusChanges.subscribe(() => {
      const { invalid, controls } = this.newTenantForm;
      const busy = this.insertOrgTenantMutation.isPending();
      const touched = controls.release_channel.touched && controls.label.touched;

      this.disableSubmit = busy || (invalid && touched);
    })
    this.subscriptions.push(statusSub);
  }

  disableSubmit = false;

  // State
  errorMessage?: string;

  // API Interaction
  tenantClusters = this.tenantClustersService.injectTenantClustersQuery().data;
  releaseChannels = this.orgServices.injectOrgReleaseChannelsQuery().data;
  sortedReleaseChannels = computed(() => this.releaseChannels()?.sort((a, b) => a.name.localeCompare(b.name)))

  setDefaultCluster = effect(() => {
    const defaultCluster = this.tenantClusters()?.find(cluster => cluster.is_default)

    if (defaultCluster) {
      // delay by a tiny amount to let the select menu register the new options
      // this may be able to be removed in the future
      setTimeout(() => {
        this.newTenantForm.controls.cluster_id.setValue(defaultCluster.id, { emitEvent: false });
        this.newTenantForm.markAsUntouched();
        this.newTenantForm.updateValueAndValidity();
      }, 10);
    }
  })

  insertOrgTenantMutation = this.orgServices.injectInsertOrgTenantMutation({
    onSuccess: () => {
      this.snackbarService.show({
        severity: 'success',
        message: 'Tenant created!',
      });
      this.dialogRef.close();
    },
    onError: (err) => {
      this.snackbarService.show({
        severity: 'error',
        message: 'Something went wrong during create.',
      });
      this.errorMessage = err.message+'. Possibly duplicate Tenant Label.';
    },
  });

  // Setup Form
  newTenantForm = this.fb.nonNullable.group({
    org_id: [this.data?.orgId || '', [Validators.required]],
    release_channel: [undefined, [Validators.required]],
    label: ['', [Validators.required, Validators.maxLength(127)]],
    notes: ['', [Validators.maxLength(1000)]],
    cluster_id: ['', [Validators.required]],
  });

  // Button Actions
  handleAlertClose(): void {
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getErrorMessage(control: FormControl): string {
    const { errors, touched } = control;

    if(!errors || !touched) {
      return '';
    }
    if(errors['required']) {
      return 'This field is required';
    }
    if(errors['maxlength']) {
      return `Maximum length of ${errors['maxlength']?.requiredLength}`
    }
    return '';
  }

  createTenant(): void {

    this.newTenantForm.markAllAsTouched();
    this.newTenantForm.updateValueAndValidity();

    const values = this.newTenantForm.value;

    if (this.newTenantForm.valid) {
      this.insertOrgTenantMutation.mutate({
        org_id: values.org_id!,
        release_channel: values.release_channel!,
        label: values.label!,
        notes: values.notes!,
        cluster_id: values.cluster_id!,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
