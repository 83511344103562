<div class="table-wrapper">
  <div class="filter-wrapper">
    <fw-text-input placeholder="Search Name" leftIcon="search"
                   [(ngModel)]="filterText" (ngModelChange)="handleFilterChange()"
                   [testId]="'text-search-name'"></fw-text-input>
    <fw-badge color="primary" [value]="filterCount.toString()" [hideZero]="true">
      <fw-button
        leftIcon="filter" rightIcon="chevron-down"
        variant="outline" [cdkMenuTriggerFor]="filterMenu" [testId]="'menu-trigger-filter'">
        Filter
      </fw-button>
    </fw-badge>
    <ng-template #filterMenu>
      <fw-menu-container width="230px" shadow="large" offset="1px">
        <fw-menu [multiSelect]="true" [useCheckbox]="true"
                 [(ngModel)]="filterRoles" (change)="handleFilterChange()" >
          <fw-menu-header>Role</fw-menu-header>
          <fw-menu-item
            title="Org Admin"
            value="ORG_ADMIN"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-orgadmin'"></fw-menu-item>
          <fw-menu-item
            title="Developer"
            value="DEVELOPER"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-developer'"></fw-menu-item>
          <fw-menu-item
            title="Member"
            value="MEMBER"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-member'"></fw-menu-item>
          <fw-menu-separator></fw-menu-separator>
        </fw-menu>
        <fw-menu [multiSelect]="true" [useCheckbox]="true"
                 [(ngModel)]="filterStatus" (change)="handleFilterChange()">
          <fw-menu-header>Status</fw-menu-header>
          <fw-menu-item
            title="Active"
            value="active"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-member'"></fw-menu-item>
          <fw-menu-item
            title="Pending Invite"
            value="pending"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-member'"></fw-menu-item>
          <fw-menu-item
            title="Deactivated"
            value="deactivated"
            [disabled]="usersQuery.isPending()"
            [testId]="'menu-item-role-member'"></fw-menu-item>
        </fw-menu>
      </fw-menu-container>
    </ng-template>
  </div>
  <mat-progress-bar mode="query" *ngIf="usersQuery.isLoading() || usersQuery.isRefetching()"></mat-progress-bar>
  <div class="table-pane">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      #dsTbSort="matSort"
      class="site-name">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-name'">
          <h4>Name</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <fw-avatar
            size="medium"
            variant="circular"
            content="initial"
            [initial]="getInitials(row.name)"
          ></fw-avatar>
          <p>{{ row.name }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-email'">
          <h4>Email</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap flex-cell">
          <p class="p2 link">{{ row.email }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-role'">
          <h4>Role</h4>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="no-wrap">
          <p>{{ row.role }}</p>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header [testId]="'table-header-status'">
          <h4>Status</h4>
        </mat-header-cell>
        <mat-cell [ngSwitch]="row.status" *matCellDef="let row" class="no-wrap">
          <fw-chip color="green" *ngSwitchCase="'active'" title="Active"></fw-chip>
          <fw-chip color="orange" *ngSwitchCase="'expired_invite'" title="Expired"></fw-chip>
          <fw-chip color="slate" *ngSwitchCase="'deactivated'" title="Deactivated"></fw-chip>
          <fw-chip color="secondary" *ngSwitchCase="'pending'" title="Pending Invite"></fw-chip>
          <fw-chip color="red" *ngSwitchCase="'cancelled'" title="Cancelled"></fw-chip>
        </mat-cell>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; index as i"
        [ngClass]='!(selectedRow) || row.id === selectedRow.id ? "selected":""'
        [testId]="'table-row-'+i"
        (click)="rowSelected(row)">
      </tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell empty-pane" [attr.colspan]="displayedColumns.length">
          <fw-layout-context *ngIf="!usersQuery.isFetching() || !usersQuery.isLoading()"
            icon="settings-gear-square"
            iconColor="secondary"
            title="No users match that criteria"
            description="Looks like there are not any users who match that criteria. Clear filters to see all users.">
            <fw-button (click)="resetFilters()">Clear Filters</fw-button>
          </fw-layout-context>
        </td>
      </tr>
    </table>

  </div>

  <fw-paginator-advanced
    *ngIf="paginatedData"
    alignment="end"
    [length]="total"
    [pageIndex]="currentPageIndex"
    [pageSize]="pageSize"
    [disabled]="usersQuery.isFetching() || usersQuery.isLoading()"
    (page)="handlePageEvent($event)">
  </fw-paginator-advanced>
</div>
