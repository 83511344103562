import * as sentry from '@sentry/angular';

import { environment } from './environments/environment';

if (['prod','test'].includes(environment.name)) {
  sentry.init({
    environment: environment.name,
    release: `${environment.package_name}@${environment.package_version}`,
    dsn: environment.sentry_dsn,
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Cancelled',
      'message: cancelled',
      'cancelled',
    ],
    integrations: [
      sentry.browserTracingIntegration(),
      sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [environment.apiBaseUrl], // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // Session Replay
    replaysSessionSampleRate: environment.production ? 0.2 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
