import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FwDialogService, FwSnackbarService } from '@flywheel-io/vision';
import { ActiveOrgService } from 'src/app/services/active-org/active-org.service';

import { orgRoleNameMap, OrgUser, UserStatus } from '../../../models/users.types';
import { AuthenticationService } from '../../../services/authentication.service';
import { PermissionService } from '../../../services/permission.service';
import { UsersService } from '../../../services/users/users.service';
import { DeactivateUserDialogComponent } from '../deactivate-user-dialog/deactivate-user-dialog.component';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { RevokeInviteDialogComponent } from '../revoke-invite-dialog/revoke-invite-dialog.component';

@Component({
  selector: 'app-view-user-dialog',
  templateUrl: './view-user-dialog.component.html',
  styleUrls: ['./view-user-dialog.component.scss'],
})
export class ViewUserDialogComponent implements OnInit {

  constructor(
    public authService: AuthenticationService,
    public activeOrgService: ActiveOrgService,
    public usersService: UsersService,
    public snackbarService: FwSnackbarService,
    public dialog: FwDialogService,
    public dialogRef: DialogRef,
    private permissions: PermissionService,
    @Inject(DIALOG_DATA) public data?: OrgUser,
  ) {}

  initials = '';
  fullName = '';
  role = '';
  email = '';
  userId = '';
  status?: UserStatus;
  canEditUsers = this.permissions.check('UPDATE_ORG_USER');

  ngOnInit(): void {
    if (this.data) {
      this.initials = this.data.given_name[0] + this.data.family_name[0];
      this.fullName = this.data.given_name + ' ' + this.data.family_name;
      this.role = orgRoleNameMap[this.data.organization_role];
      this.email = this.data.email;
      this.userId = this.data.user_id;
      this.status = this.data.status;
    }
  }

  updateUserMutation = this.usersService.injectOrgUserUpdateMutation({
    onSuccess: () => {
      this.snackbarService.show({
        severity: 'success',
        message: `${this?.data?.email} reactivated!`,
      });
      this.dialogRef.close();
    },
    onError: () => {
      this.snackbarService.show({
        severity: 'error',
        message: 'Something went wrong during user update.',
      });
    },
  });

  resendInviteMutation = this.usersService.injectResendInviteMutation({
    onSuccess: () => {
      this.snackbarService.show({
        severity: 'success',
        message: 'User invite was resent!',
      });
      // reflect the change in the dialog
      this.status = 'pending';
    },
    onError: () => {
      this.snackbarService.show({
        severity: 'error',
        message: 'Something went wrong resending the invite.',
      });
    },
  })

  close(): void {
    this.dialogRef.close();
  }

  handleEdit(): void {
    if (this.canEditUsers()) {
      this.dialog.openDialog(EditUserDialogComponent, { data: this.data });
      this.close();
    }
  }

  handleResend(): void {
    if (this.canEditUsers() && this.userId) {
      this.resendInviteMutation.mutate(this.userId);
    }
  }

  handleRevoke(): void {
    this.dialog.openDialog(RevokeInviteDialogComponent, { data: this.data });
    this.close();
  }

  handleReactivate(): void {
    const activeOrg = this.activeOrgService.org();

    if (this.canEditUsers() && this.data && activeOrg) {
      this.updateUserMutation.mutate({
        org_id: activeOrg.organization_id,
        user_id: this.data.user_id,
        organization_role: this.data.organization_role,
        status: 'active',
      });
    } else {
      alert('Not Authorized');
    }
  }

  handleDeactivate(): void {
    const activeOrg = this.activeOrgService.org();

    if (this.canEditUsers() && this.data && activeOrg) {
      this.dialog.openDialog(DeactivateUserDialogComponent, { data: this.data });
      this.close();
    } else {
      alert('Not Authorized');
    }
  }
}
