import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FwButtonModule, FwCardModule, FwChipModule, FwDateInputModule, FwFormHeadingModule, FwIconModule, FwLayoutsModule, FwMenuModule, FwPopoverModule, FwProgressModule, FwSectionHeadingModule, FwSelectMenuModule } from '@flywheel-io/vision';
import { NgApexchartsModule } from 'ng-apexcharts';

import { ThemeModule } from '../../theme/theme.module';
import { BillingComponent } from './billing.component';
import { BillingChartComponent } from './billing-chart/billing-chart.component';
import { BillingFiltersComponent } from './billing-filters/billing-filters.component';
import { CostCardComponent } from './cost-card/cost-card.component';
import { ProportionalCostComponent } from './proportional-cost/proportional-cost.component';


@NgModule({
  imports: [
    ThemeModule,
    NgApexchartsModule,
    FwLayoutsModule,
    FwSelectMenuModule,
    FwSectionHeadingModule,
    FwButtonModule,
    FwIconModule,
    FwFormHeadingModule,
    FwCardModule,
    FwDateInputModule,
    FwMenuModule,
    FwPopoverModule,
    OverlayModule,
    CommonModule,
    ReactiveFormsModule,
    FwProgressModule,
    FwChipModule,
  ],
  declarations: [
    BillingComponent,
    CostCardComponent,
    ProportionalCostComponent,
    BillingFiltersComponent,
    BillingChartComponent,
  ],
  exports: [],
})
export class BillingModule {
}
