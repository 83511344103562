import { Component, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ActiveOrgService } from '../../services/active-org/active-org.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';

@Component({
  selector: 'app-users',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent {
  adminOrgId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orgsService: OrganizationsService,
    public activeOrgService: ActiveOrgService,
  ) {
    if (route.snapshot && route.snapshot.params) {
      this.adminOrgId = route.snapshot.params['admin_org'];
    }
  }

  tenants = this.orgsService.injectOrgTenantsQuery().data;
  // filtering deactivated tenants as active tenant count is more useful at a glance
  tenantCount = computed(() => (this.tenants()?.items.filter(tenant => tenant.deployment_status !== 'deactivated').length || 0))

  // needs to be an arrow function to fix scoping issues around `this` when it's called
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goBack = (): any => {
    const hasHistory = window.history.length > 1;
    const lastPageWasThisSite = window.history.state.navigationId > 1;

    if(hasHistory && lastPageWasThisSite) {
      window.history.back();
    }
    else {
      this.router.navigate(['..', 'dashboard'], { relativeTo: this.route });
    }
  }

}
