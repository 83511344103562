<fw-dialog width="small" [showClose]="true" icon="data-tree" [title]="'New Tenant'">

  <fw-dialog-content style="overflow-x: auto">
    <div class="body-content" [formGroup]="newTenantForm">
      <fw-alert
        *ngIf="errorMessage" severity="error" icon="warning-circle" [title]="'Errors'" [showClose]="true"
        (close)="handleAlertClose()">
        {{ errorMessage }}
      </fw-alert>
      <fw-grid>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-wrapped-input title="Name" [errorText]="getErrorMessage(newTenantForm.controls.label)">
            <fw-text-input placeholder="Tenant Name" formControlName="label"></fw-text-input>
          </fw-wrapped-input>
        </fw-grid-item>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-wrapped-input title="Release Channel" [errorText]="getErrorMessage(newTenantForm.controls.release_channel)">
            <fw-select
              *ngIf="releaseChannels"
              [options]="sortedReleaseChannels() || []"
              placeholder="Select Channel..."
              width="100%"
              formControlName="release_channel"
              valueProperty="name"
              titleProperty="name"
            ></fw-select>
          </fw-wrapped-input>
        </fw-grid-item>
        <fw-grid-item [cols]="6" variant="card-background">
          <fw-wrapped-input title="Tenant Cluster" [errorText]="getErrorMessage(newTenantForm.controls.cluster_id)">
            <fw-select
              [options]="tenantClusters() || []"
              placeholder="Select Cluster..."
              width="100%"
              formControlName="cluster_id"
              valueProperty="id"
              titleProperty="name"
            ></fw-select>
          </fw-wrapped-input>
        </fw-grid-item>
        <fw-grid-item [cols]="12" variant="card-background">
          <fw-wrapped-input title="Notes" [errorText]="getErrorMessage(newTenantForm.controls.notes)">
            <fw-textarea-input
              placeholder="Notes"
              formControlName="notes"
              [minRows]="7"
              [maxRows]="10"
              [maxlength]="1000"
            ></fw-textarea-input>
          </fw-wrapped-input>
        </fw-grid-item>
      </fw-grid>
    </div>
  </fw-dialog-content>
  <fw-dialog-actions>
    <div class="flex"></div>
    <fw-button variant="outline" (click)="cancel()" [testId]="'btn-add-tenant-cancel'">Cancel</fw-button>
    <fw-button (click)="createTenant()" [testId]="'btn-add-tenant-send'" [disabled]="disableSubmit">Create Tenant</fw-button>
  </fw-dialog-actions>
</fw-dialog>
