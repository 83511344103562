import { computed, Injectable, Signal } from '@angular/core';

import { Permission } from '../models/organization';
import { ActiveOrgService } from './active-org/active-org.service';
import { SelfService } from './self/self.service';



@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private selfService: SelfService,
    private activeOrgService: ActiveOrgService,
  ) {}


  /**
 *  Checks that the user has specified permission
 * @param permission permission string to check for
 * @param orgId *optional* falls back to current active org
 * @returns a signal of whether or not the user has the permission
 */
  check(permission: Permission | Permission[], orgId?: string): Signal<boolean> {
    return computed(() => {

      const hasPlatformPermission = this.checkPlatformPermission(permission);

      if (hasPlatformPermission()) {
        return true;
      }

      const self = this.selfService.selfSignal();
      const relevantOrgId = orgId || this.activeOrgService.org()?.organization_id;
      const relevantOrg = self?.organizations.find((org) => org.organization_id === relevantOrgId);
      const activeInOrg = relevantOrg?.status === 'active';

      if (!relevantOrg || !activeInOrg) {
        return false;
      }

      let hasOrgScopedPermission = false;

      if (Array.isArray(permission)) {
        hasOrgScopedPermission = permission.every(perm => relevantOrg.organization_role_permissions.includes(perm))
      }
      else {
        hasOrgScopedPermission = relevantOrg.organization_role_permissions.includes(permission);
      }

      return hasOrgScopedPermission;
    })
  }

  checkPlatformPermission(permission: Permission | Permission[]): Signal<boolean> {
    return computed(() => {

      const self = this.selfService.selfSignal();
      const platformPermissions = self?.platform_role_permissions;

      if(!platformPermissions) {
        return false;
      }

      let hasPermission = false;

      if (Array.isArray(permission)) {
        hasPermission = permission.every(perm => platformPermissions.includes(perm))
      }
      else {
        hasPermission = platformPermissions.includes(permission);
      }

      return hasPermission;
    })
  }
}
