import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FwDialogService, FwSnackbarService } from '@flywheel-io/vision';

import { OrgUser } from '../../../models/users.types';
import { UsersService } from '../../../services/users/users.service';
import { ViewUserDialogComponent } from '../view-user-dialog/view-user-dialog.component';

@Component({
  selector: 'app-revoke-invite-dialog',
  templateUrl: './revoke-invite-dialog.component.html',
  styleUrls: ['./revoke-invite-dialog.component.scss'],
})
export class RevokeInviteDialogComponent {
  constructor(
    public dialog: FwDialogService,
    public dialogRef: DialogRef,
    private usersService: UsersService,
    private snackbarService: FwSnackbarService,
    @Inject(DIALOG_DATA) public data: OrgUser,
  ) {}

  goBack(): void {
    this.dialog.openDialog(ViewUserDialogComponent, { data: this.data });
    this.dialogRef.close();
  }

  handleRevoke(): void {
    this.revokeInviteMutation.mutate(this.data.user_id);
  }

  revokeInviteMutation = this.usersService.injectRevokeInviteMutation({
    onSuccess: () => {
      this.snackbarService.show({
        severity: 'success',
        message: 'User invite was successfully revoked!',
      });
      this.dialogRef.close();
    },
    onError: () => {
      this.snackbarService.show({
        severity: 'error',
        message: 'Something went wrong during invite revocation.',
      });
    },
  })
}
