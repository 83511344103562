import { HttpClient } from '@angular/common/http';
import { computed, Injectable, Signal, WritableSignal } from '@angular/core';
import {
  CreateMutationResult,
  CreateQueryResult,
  injectMutation,
  injectQuery,
  injectQueryClient,
  MutationOptions,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom, map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ManagerError } from '../../models/manager-error';
import { Organization, OrgRequestOptions } from '../../models/organization';
import { ReleaseChannels } from '../../models/release-channels';
import { Tenant } from '../../models/tenant';
import { Paginated } from '../../models/users.types';
import { ActiveOrgService } from '../active-org/active-org.service';
import { PermissionService } from '../permission.service';
import { buildSearchParams } from '../service-utils';

export interface BasicOrganization {
  id: string;
  name: string;
  label?: string;
  fw_org_id: string;
  tenant_count: number;
}

interface InsertOrgPayload {
  name: string;
  label: string;
  initial_user: {
    given_name: string;
    family_name: string;
    email: string;
  };
}

interface InsertOrgTenantPayload {
  org_id: string;
  label: string;
  release_channel: string;
  notes: string;
}

interface UpdateOrgTenantPayload {
  org_id: string;
  tenant_id: string;
  label?: string;
  deployment_status?: string;
  release_channel?: string;
  notes?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  client;
  constructor(
    private http: HttpClient,
    private permissionService: PermissionService,
    private activeOrgService: ActiveOrgService,
  ) {
    this.client = injectQueryClient();
  }

  /* get All Orgs */
  getPaginatedOrganizations(options: OrgRequestOptions): Observable<Paginated<BasicOrganization>> {
    return this.http.get<Paginated<BasicOrganization>>(`${environment.apiBaseUrl}/organizations?${buildSearchParams(options)}`);
  }

  getAllOrganizations(): Observable<BasicOrganization[]> {

    // just hit the paginated end point with an absurd size and unwrap it
    const paginatedOrgs$ = this.http.get<Paginated<BasicOrganization>>(`${environment.apiBaseUrl}/organizations?${buildSearchParams({ size: 50000 })}`)

    return paginatedOrgs$.pipe(
      map(paginatedOrgs => {
        return paginatedOrgs.items;
      }),
    );
  }

  injectAllOrganizationsQuery(): CreateQueryResult<BasicOrganization[], Error> {
    return injectQuery(() => ({
      queryKey: ['organizations', 'all', this.permissionService.checkPlatformPermission('GET_ORG')],
      enabled: this.permissionService.checkPlatformPermission('GET_ORG')(),
      queryFn: () => lastValueFrom(this.getAllOrganizations()),
    }));
  }

  allOrganizationsQuery = this.injectAllOrganizationsQuery();
  allOrganizationsSignal = this.allOrganizationsQuery.data;

  injectOrganizationsQuery(options: Signal<OrgRequestOptions>): CreateQueryResult<Paginated<BasicOrganization>, Error> {
    return injectQuery(() => ({
      queryKey: ['organizations', options],
      enabled: true,
      queryFn: () => {
        return lastValueFrom(this.getPaginatedOrganizations(options()));
      },
    }));
  }

  /* insert an org */
  private insertOrg(payload: InsertOrgPayload): Observable<BasicOrganization> {
    return this.http.post<BasicOrganization>(`${environment.apiBaseUrl}/organizations`, payload);
  }

  injectInsertOrgMutation(options?: MutationOptions<BasicOrganization, ManagerError, InsertOrgPayload>): CreateMutationResult<BasicOrganization, ManagerError, InsertOrgPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: InsertOrgPayload) => {
        return lastValueFrom(this.insertOrg(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['orgs'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  /* get All Tenants for an Org */
  getOrgTenants(orgId: string): Observable<Paginated<Tenant>> {
    return this.http.get<Paginated<Tenant>>(`${environment.apiBaseUrl}/organizations/${orgId}/tenants`);
  }

  injectOrgTenantsQuery(orgId?: string): CreateQueryResult<Paginated<Tenant>, Error> {

    const orgIdToFetch = computed(() => {
      const activeOrg = this.activeOrgService.org();
      return orgId || activeOrg?.organization_id || '';
    })

    return injectQuery(() => ({
      queryKey: ['orgTenants', orgIdToFetch()],
      enabled: true,
      staleTime: 10000,
      queryFn: () => {
        return lastValueFrom(this.getOrgTenants(orgIdToFetch()));
      },
    }));
  }

  /* insert a tenant */
  private insertOrgTenant(payload: InsertOrgTenantPayload): Observable<Tenant> {
    return this.http.post<Tenant>(`${environment.apiBaseUrl}/organizations/${payload.org_id}/tenants`, {
      ...payload,
      org_id: undefined,
    });
  }

  injectInsertOrgTenantMutation(options?: MutationOptions<Tenant, ManagerError, InsertOrgTenantPayload>): CreateMutationResult<Tenant, ManagerError, InsertOrgTenantPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: InsertOrgTenantPayload) => {
        return lastValueFrom(this.insertOrgTenant(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['orgTenants', variable?.org_id] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  /* update a tenant */
  private updateOrgTenant(payload: UpdateOrgTenantPayload): Observable<Tenant> {
    return this.http.put<Tenant>(`${environment.apiBaseUrl}/organizations/${payload.org_id}/tenants/${payload.tenant_id}`, {
      ...payload,
      org_id: undefined,
    });
  }

  injectUpdateOrgTenantMutation(options?: MutationOptions<Tenant, ManagerError, UpdateOrgTenantPayload>): CreateMutationResult<Tenant, ManagerError, UpdateOrgTenantPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: UpdateOrgTenantPayload) => {
        return lastValueFrom(this.updateOrgTenant(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['orgTenants', variable?.org_id] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }

  /* get All ReleaseChannels Channels for an Org */
  getOrgReleaseChannels(orgId: WritableSignal<string>): Observable<ReleaseChannels[]> {
    return this.http.get<ReleaseChannels[]>(`${environment.apiBaseUrl}/organizations/${orgId()}/release_channels`);
  }

  injectOrgReleaseChannelsQuery(orgId: WritableSignal<string>): CreateQueryResult<ReleaseChannels[], Error> {
    return injectQuery(() => ({
      queryKey: ['orgReleases', orgId(), orgId],
      enabled: true,
      queryFn: () => {
        return lastValueFrom(this.getOrgReleaseChannels(orgId));
      },
    }));
  }
}

/*utils*/

export const orgTitles = [
  { value: 'Contributor', title: 'Contributor' },
  { value: 'Data Manager', title: 'Data Manager' },
  { value: 'Data Scientist', title: 'Data Scientist' },
  { value: 'ML Engineer', title: 'ML Engineer' },
  { value: 'Principal Investigator', title: 'Principal Investigator' },
  { value: 'Radiologist', title: 'Radiologist' },
  { value: 'Research Coordinator', title: 'Research Coordinator' },
  { value: 'Site Administrator', title: 'Site Administrator, IT' },
  { value: 'Other', title: 'Other' },
];

// TODO: this and random color selection in general should move to vision
export const hash = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
};

export const getOrgInitials = (org: Organization): string | undefined => {
  const capitalLetterRegex = /[A-Z]/g;
  const capitals = [...org.name.matchAll(capitalLetterRegex) || []];

  if (capitals.length >= 2) {
    return capitals[0][0] + capitals[1][0];
  }

  if (org.name.length >= 2) {
    return org.label?.substring(0, 2);
  }

  return '';
};

export const stringToAvatarColor = (str: string): 'primary' | 'secondary' | 'red' | 'warning' | 'success' => {
  const options = ['primary', 'secondary', 'red', 'warning', 'success'];
  return options[hash(str) % options.length] as 'primary' | 'secondary' | 'red' | 'warning' | 'success';
};
