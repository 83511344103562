import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, effect, HostListener, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FwDialogService } from '@flywheel-io/vision';
import { FwPaginatorEvent } from '@flywheel-io/vision/components/paginator/paginator.model';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';
import { debounce } from 'lodash-es';
import { orgRoleNameMap, OrgUser, Paginated, UsersRequestOptions } from 'src/app/models/users.types';
import { UsersService } from 'src/app/services/users/users.service';

import { PermissionService } from '../../../services/permission.service';
import { ViewUserDialogComponent } from '../view-user-dialog/view-user-dialog.component';

interface DataRow {
  id: string;
  name: string;
  email: string;
  role: string;
  status: string;
  user: OrgUser;
}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent {
  @HostListener('document:click') outsideClick(): void {
    if (this._isOpen && this.trigger) {
      this.trigger.close();
      this._isOpen = false;
    }
    if (this.trigger && this.trigger.isOpen()) {
      this._isOpen = true;
    }
  }

  @ViewChild('dsTbSort') dsTbSort = new MatSort();
  @ViewChild(CdkMenuTrigger) trigger?: CdkMenuTrigger;
  dataSource = new MatTableDataSource<DataRow>();
  displayedColumns: string[] = [
    'name',
    'email',
    'role',
    'status',
  ];
  usersQuery: CreateQueryResult<Paginated<OrgUser>>;
  paginatedData?: Paginated<OrgUser>;
  selectedRow: DataRow | null = null;
  currentPageIndex: number = 0;
  pageSize: number = 10;
  total: number = 0;
  options: WritableSignal<UsersRequestOptions>;
  filterRoles: string[] = [];
  filterStatus: string[] = [];
  filterText: string = '';
  filterCount: number = 0;
  private _isOpen = false;

  constructor(
    private permissions: PermissionService,
    private usersService: UsersService,
    public dialog: FwDialogService,
  ) {
    this.handleFilterChange = debounce(this.handleFilterChange, 1000);
    this.options = signal({ size: this.pageSize });
    this.usersQuery = this.usersService.injectOrgUsersQuery(this.options);

    effect(() => {
      const users = this.usersQuery.data()?.items || [];
      this.paginatedData = this.usersQuery.data();
      const dataRows = users.map(user => {
        return {
          name: user.given_name + ' ' + user.family_name,
          email: user.email,
          role: orgRoleNameMap[user.organization_role],
          status: user.status,
          id: user.user_id,
          user: user,
        };
      });

      this.selectedRow = dataRows[0];
      this.dataSource.data = dataRows;
      this.dataSource.sort = this.dsTbSort;
      if (this.paginatedData?.total) {
        this.total = this.paginatedData?.total;
      }
    });
  }

  rowSelected(row: DataRow): void {

    const canViewUser = this.permissions.check('GET_USER');

    this.selectedRow = row;
    if (canViewUser()) {
      this.dialog.openDialog(ViewUserDialogComponent, { data: row.user });
    }
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    return names[0].substring(0, 1) + names[1].substring(0, 1);
  }

  resetFilters(): void {
    this.filterText = '';
    this.filterStatus = [];
    this.filterRoles = [];
    this.handleFilterChange();
  }

  handleFilterChange(): void {
    if (!this.usersQuery.isPending()) {
      this.filterCount = (this.filterRoles.length + this.filterStatus.length);
      const options: UsersRequestOptions = {
        size: this.pageSize,
        filter: this.filterText,
        statuses: this.filterStatus,
        org_roles: this.filterRoles,
      };
      // don't call unless the filters have actually changed
      const newFilters = JSON.stringify({ ...options, cursor: null });
      const oldFilters = JSON.stringify({ ...this.options(), cursor: null });
      if (newFilters !== oldFilters) {
        this.currentPageIndex = 0;
        this.options.update(() => options);
        this.usersQuery.refetch().finally();
      }
    }
  }

  async handlePageEvent(evt: FwPaginatorEvent): Promise<void> {
    if (!this.usersQuery.isPending()) {
      const options: UsersRequestOptions = {
        size: this.pageSize,
        filter: this.filterText,
        statuses: this.filterStatus,
        org_roles: this.filterRoles,
      };
      if (evt.pageSize !== this.pageSize) {
        // page size change
        options.size = evt.pageSize;
        this.currentPageIndex = 0;
        this.pageSize = evt.pageSize;
      } else if (evt.pageIndex > this.currentPageIndex) {
        // forward
        options.cursor = this.paginatedData?.next_cursor;
        this.currentPageIndex = evt.pageIndex;
      } else if (evt.pageIndex < this.currentPageIndex) {
        // back
        options.cursor = this.paginatedData?.previous_cursor;
        this.currentPageIndex = evt.pageIndex;
      }
      this.options.update(() => options);
      await this.usersQuery.refetch().finally();
    }
  }
}
