import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, of, switchMap, tap } from 'rxjs';

import { FeatureFlagService, FlagName } from '../services/feature-flag/feature-flag.service';

/**
 * Checks the passed feature flag(s) are on
 */
export const featureFlagGuard = (flags: FlagName | FlagName[]): CanActivateFn => {
  return (route) => {
    const flagsService = inject(FeatureFlagService);
    const router = inject(Router);

    // we need to parse the query param flags here
    // we can't rely on the feature flag service as we're inside a navigation event
    //.qParams don't get emitted to it until navigation completes
    const flagOverrides = flagsService.parseQueryParamsToFlags(route.queryParams);

    return flagsService.getApiFlags().pipe(
      switchMap(apiFlags => {
        const allFlags = {
          ...apiFlags,
          ...flagOverrides,
        }
        const requiredFlags = Array.isArray(flags) ? flags : [flags];
        const allFlagsGood = requiredFlags.every(requiredFlag => allFlags[requiredFlag]);
        return of(allFlagsGood);
      }),
      tap(flagRequirementsMet => {
        if (!flagRequirementsMet) {
          throw new Error('Required Flags are not enabled');
        }
      }),
      catchError(() => {
        router.navigate(['404']);
        return of(false);
      }),
    );
  }
}
