import { Interval } from 'date-fns';

export type CloudProvider = 'AWS' | 'AZURE' | 'GCP';

export type BillingDisplayCategory = 'Compute' | 'Storage' | 'Other';

export type BillingCategory = 'Compute' | 'Integration' | 'Networking' | 'Other' | 'Security' | 'Storage';

export const categoryToDisplayCategory = (category: BillingCategory): BillingDisplayCategory => {

  const map: { [K in BillingCategory]: BillingDisplayCategory } = {
    'Compute': 'Compute',
    'Integration': 'Other',
    'Networking': 'Other',
    'Other': 'Other',
    'Security': 'Other',
    'Storage': 'Storage',
  }

  return map[category];


}
export type CategoryTotals = Record<BillingDisplayCategory, number>;

export type BillingFilterState = {
  range: Interval,
  tenantId: string,
  dirty: boolean,
}

export type BillingLineItem = {
  total_cost: number;
  date: Date;
  tenant_path: string;
  category: BillingCategory;
  detail: string;
  region: string;
  cloud_provider: CloudProvider;
  tenant_id: string;
}

export type TimeMode = 'day' | 'month' | 'year';

export type TimeGroups = {
  mode: TimeMode,
  groups: Date[],
}
