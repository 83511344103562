<fw-popover [trigger]="storageTrigger" position="above" action="mouseenter">
  <div class="popover-content">
    <h2 class="vision-h2">Storage</h2>
    <h2 class="vision-h2">{{proportions.Storage}}%</h2>
  </div>
</fw-popover>
<div class="storage" [style.width.%]="proportions.Storage">
  <p #storageTrigger>Storage</p>
</div>
<fw-popover [trigger]="computeTrigger" position="above" action="mouseenter">
  <div class="popover-content">
    <h2 class="vision-h2">Compute</h2>
    <h2 class="vision-h2">{{proportions.Compute}}%</h2>
  </div>
</fw-popover>
<div class="compute" [style.width.%]="proportions.Compute">
  <p #computeTrigger>Compute</p>
</div>
<fw-popover [trigger]="otherTrigger" position="above" action="mouseenter">
  <div class="popover-content">
    <h2 class="vision-h2">Other</h2>
    <h2 class="vision-h2">{{proportions.Other}}%</h2>
  </div>
</fw-popover>
<div class="other" [style.width.%]="proportions.Other">
  <p #otherTrigger>Other</p>
</div>

