<div *ngIf="!org">
  <app-loader></app-loader>
</div>
<app-theme [fitted]="true" page="shaded" *ngIf="org">
<div class="dialog vision-shadow-large">
  <app-word-mark></app-word-mark>
  <div class="header">
    <h1>Welcome to Flywheel</h1>
    <p>Let's make sure your information is correct.</p>
  </div>
  <div class="form" [formGroup]="onboardForm">
    <fw-grid>
      <fw-grid-item [cols]="12">
        <div class="form-item">
          <label>Organization</label>
          <fw-contained-input
            inputVariant="none"
            [title]="org.name"
            [description]="roleNameMap[org.organization_role]">
            <fw-avatar
              [color]="stringToAvatarColor(org.name)"
              size="medium" variant="rounded" content="initial"
              [initial]="getOrgInitials(org) || 'ab'">
            </fw-avatar>
          </fw-contained-input>
        </div>
      </fw-grid-item>
      <fw-grid-item [cols]="6">
        <div class="form-item">
          <label>First Name</label>
          <fw-text-input
            formControlName="firstName"
            [errorText]="getErrorMessage('firstName')">
          </fw-text-input>
        </div>
      </fw-grid-item>
      <fw-grid-item [cols]="6">
        <div class="form-item">
          <label>Last Name</label>
          <fw-text-input
            formControlName="lastName"
            [errorText]="getErrorMessage('lastName')">
          </fw-text-input>
        </div>
      </fw-grid-item>
      <fw-grid-item [cols]="12">
        <div class="form-item">
          <label>What is your role within your organization?</label>
          <fw-multi-select
            width="433px"
            [maxSelectedShown]="2"
            placeholder="Select role"
            formControlName="jobTitles"
            [useCheckbox]="true"
            [filterItemsOnSelect]="false"
            [closeOnSelect]="false"
            [options]="orgTitles">
          </fw-multi-select>
        </div>
      </fw-grid-item>
    </fw-grid>
  </div>
  <fw-button (click)="submitForm()" [disabled]="!onboardForm.valid || updateOrgUserDetails.isPending() || updateSelf.isPending()" rightIcon="arrow-forward">Continue to Flywheel</fw-button>
</div>
</app-theme>
