import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FwDialogService, FwSnackbarService } from '@flywheel-io/vision';
import { ActiveOrgService } from 'src/app/services/active-org/active-org.service';

import { orgRoleNameMap, OrgUser } from '../../../models/users.types';
import { AuthenticationService } from '../../../services/authentication.service';
import { PermissionService } from '../../../services/permission.service';
import { UsersService } from '../../../services/users/users.service';
import { ViewUserDialogComponent } from '../view-user-dialog/view-user-dialog.component';

@Component({
  selector: 'app-view-user-dialog',
  templateUrl: './deactivate-user-dialog.component.html',
  styleUrls: ['./deactivate-user-dialog.component.scss'],
})
export class DeactivateUserDialogComponent implements OnInit {
  updateUserMutation;
  lastActionMessage: string = '';

  constructor(
    public authService: AuthenticationService,
    public activeOrgService: ActiveOrgService,
    public usersService: UsersService,
    public snackbarService: FwSnackbarService,
    public dialog: FwDialogService,
    public dialogRef: DialogRef,
    private permissions: PermissionService,
    @Inject(DIALOG_DATA) public data?: OrgUser,
  ) {
    this.updateUserMutation = this.usersService.injectOrgUserUpdateMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: this.lastActionMessage,
        });
        this.dialogRef.close();
      },
      onError: () => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during user update.',
        });
      },
    });
  }

  canDeactivateUsers = this.permissions.check('UPDATE_ORG_USER');

  initials = '';
  fullName = '';
  role = '';
  email = '';
  userId = '';
  status: string = '';

  ngOnInit(): void {
    if (this.data) {
      this.initials = this.data.given_name[0] + this.data.family_name[0];
      this.fullName = this.data.given_name + ' ' + this.data.family_name;
      this.role = orgRoleNameMap[this.data.organization_role];
      this.email = this.data.email;
      this.userId = this.data.user_id;
      this.status = this.data.status;
    }
  }

  close(): void {
    this.dialog.openDialog(ViewUserDialogComponent, { data: this.data });
    this.dialogRef.close();
  }

  handleDeactivate(): void {
    const activeOrg = this.activeOrgService.org();

    if (this.canDeactivateUsers() && this.data && activeOrg) {
      this.lastActionMessage = `${this.data.email} deactivated!`;
      this.updateUserMutation.mutate({
        org_id: activeOrg.organization_id,
        user_id: this.data.user_id,
        organization_role: this.data.organization_role,
        status: 'deactivated',
      });
      this.data.status = 'deactivated';
      this.close();
    } else {
      alert('Not Authorized')
    }
  }
}
