<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <fw-icon class="header-icon">close-circled</fw-icon>
      <h3>Revoke Invite</h3>
  </fw-dialog-header>

  <fw-dialog-content>
    <p>
      Are you sure you want to revoke the invite sent to
      <strong>{{data.email}}</strong>?
      Once revoked, the user account associated with that email
      will be cancelled.
    </p>
  </fw-dialog-content>

  <fw-dialog-actions>
    <fw-button
      (click)="goBack()"
      color="primary"
      leftIcon="arrow-back"
      variant="outline"
    >
      Back
    </fw-button>
    <div class="flex-space"></div>
    <fw-button
      (click)="goBack()"
      variant="outline"
      color="danger"
      [testId]="'btn-deactivate-user-cancel'"
    >
      Cancel
    </fw-button>
    <fw-button
      (click)="handleRevoke()"
      [disabled]="revokeInviteMutation.isPending()"
      variant="solid"
      color="danger"
    >
      Revoke Invite
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
