<fw-layout-group [stackable]="true">
  <div class="navigation">
    <a routerLink="/select-org" [testId]="'link-home-logo'">
      <fw-app-icon
        style="margin: -4px"
        icon="flywheel-mark-logo"
        size="medium"
        variant="static"
        color="light"
        [animated]="true"
      ></fw-app-icon>
    </a>
    <fw-breadcrumbs>
      <fw-crumb title="Flywheel" [testId]="'breadcrumb-home'"></fw-crumb>
    </fw-breadcrumbs>
    <p class="env-label vision-p1" *ngIf="envLabel">{{envLabel}}</p>
  </div>
  <app-toolbar></app-toolbar>
</fw-layout-group>
