import { HttpClient } from '@angular/common/http';
import { computed, Injectable, Signal } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { captureException } from '@sentry/angular';
import { CreateQueryResult, injectQuery } from '@tanstack/angular-query-experimental';
import { catchError, combineLatest, lastValueFrom, map, Observable, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';

// this is not an exhaustive list if you're using a flag you should add it to this
const flagNames = ['billing_backend', 'billing_frontend'] as const;
export type FlagName = typeof flagNames[number];

const isFlagName = (maybeFlag: string): maybeFlag is FlagName => {
  return flagNames.includes(maybeFlag as FlagName);
}

export type FeatureFlags = {
  [K in FlagName]?: boolean
}


@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {

  public flagsQuery;
  public billingEnabled = this.getFlag('billing_frontend');

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.flagsQuery = this.injectFeatureFlagsQuery();
  }

  getFlag(flagName: FlagName): Signal<boolean> {
    return computed(() => {
      return this.flagsQuery.data()?.[flagName] || false
    })
  }

  getApiFlags(): Observable<FeatureFlags> {
    return this.http.get<FeatureFlags>(`${environment.apiBaseUrl}/features`).pipe(
      catchError(err => {
        // report to sentry
        captureException(err);
        return of({});
      }),
    );
  }

  /**
   * Interprets query params as feature flags
   * @returns Observable of query params coerced to booleans
   */
  getFlagOverrides(): Observable<FeatureFlags> {

    return this.route.queryParams.pipe(
      map(this.parseQueryParamsToFlags),
    );
  }

  getCombinedFlags(): Observable<FeatureFlags> {
    return combineLatest([this.getApiFlags(), this.getFlagOverrides()]).pipe(
      // this is needed to guarantee the observable completes
      take(1),
      map(([flags, flagOverrides]) => {
        // combine the flags and overrides favoring the overrides
        return {
          ...flags,
          ...flagOverrides,
        }
      }),
    )
  }

  injectFeatureFlagsQuery(): CreateQueryResult<FeatureFlags, Error> {
    return injectQuery(() => ({
      queryKey: ['flags'],
      queryFn: () => lastValueFrom(this.getCombinedFlags()),
    }))
  }

  parseQueryParamsToFlags(qParams: Params): FeatureFlags {
    const coercedParams: FeatureFlags = {};
    for (const key in qParams) {
      if (isFlagName(key)) {
        // if the query param is '' or 'true' coerce to boolean true
        coercedParams[key] = qParams[key] === '' || qParams[key] === 'true';
      }
    }
    return coercedParams;
  }
}
