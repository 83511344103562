import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
  Component,
  effect,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FwDialogService } from '@flywheel-io/vision';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';
import { Paginated } from 'src/app/models/users.types';

import { environment } from '../../../../environments/environment';
import { Tenant } from '../../../models/tenant';
import { ActiveOrgService } from '../../../services/active-org/active-org.service';
import { OrganizationsService } from '../../../services/organizations/organizations.service';
import { EditTenantDialogComponent } from '../edit-tenant-dialog/edit-tenant-dialog.component';

@Component({
  selector: 'app-tenants-table',
  templateUrl: './tenant-table.component.html',
  styleUrls: ['./tenant-table.component.scss'],
})
export class TenantTableComponent implements OnInit {
  @HostListener('document:click') outsideClick(): void {
    if (this._isOpen && this.trigger) {
      this.trigger.close();
      this._isOpen = false;
    }
    if (this.trigger && this.trigger.isOpen()) {
      this._isOpen = true;
    }
  }

  @ViewChild('dsTbSort') dsTbSort = new MatSort();
  @ViewChild(CdkMenuTrigger) trigger?: CdkMenuTrigger;
  dataSource = new MatTableDataSource<Tenant>();
  displayedColumns: string[] = [
    'path',
    'label',
    'release_channel',
    'deployment_status',
    'notes',
    'actions',
  ];
  orgId = this.activeOrgService.org()!.organization_id;
  orgTenantsQuery?: CreateQueryResult<Paginated<Tenant>>;
  paginatedData?: Paginated<Tenant>;
  total: number = 0;
  private _isOpen = false;

  constructor(
    private activeOrgService: ActiveOrgService,
    private orgServices: OrganizationsService,
    public dialog: FwDialogService,
  ) {
    this.orgTenantsQuery = this.orgServices.injectOrgTenantsQuery();

    effect(() => {
      if (this.orgTenantsQuery) {
        const tenants = this.orgTenantsQuery.data()?.items || [];
        this.paginatedData = this.orgTenantsQuery.data();
        const dataRows = tenants;

        this.dataSource.data = dataRows;
        this.dataSource.sort = this.dsTbSort;
        if (this.paginatedData?.total) {
          this.total = this.paginatedData?.total;
        }
      }
    });
  }

  ngOnInit(): void {
    this.dsTbSort.sort(({ id: 'deployment_status', start: 'desc' }) as MatSortable);
  }

  refresh(): void {
    if (this.orgTenantsQuery) {
      this.orgTenantsQuery?.refetch();
    }
  }

  rowSelected(row: Tenant): void {
    this.dialog.openDialog(EditTenantDialogComponent, { data: row });
  }

  openTenant(event: Event, row: Tenant): void {
    event.stopPropagation();
    const tenantUrl = `https://${row.path + environment.tenantBaseUrl}`;
    window.open(tenantUrl, row.path);
  }

  handleSearchChanged(searchTerm: string): void {
    this.dataSource.filterPredicate = (t, filter) => {
      return t.path.toLowerCase().includes(filter.toLowerCase()) || t.label.toLowerCase().includes(filter.toLowerCase());
    };
    this.dataSource.filter = searchTerm;
  }
}
