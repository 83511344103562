import { CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  FwAlertModule, FwAvatarModule, FwBadgeModule,
  FwButtonModule, FwChipModule, FwDialogsModule,
  FwIconButtonModule, FwIconModule, FwLayoutsModule, FwMenuModule, FwPaginatorModule,
  FwSectionHeadingModule, FwSelectMenuModule, FwSnackbarModule, FwTextAreaInputModule, FwTextInputModule,
} from '@flywheel-io/vision';

import { TestIdDirective } from '../../directives/test-id.directive';
import { ThemeModule } from '../../theme/theme.module';
import { DeactivateUserDialogComponent } from './deactivate-user-dialog/deactivate-user-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { InvitationSentDialogComponent } from './invitation-sent-dialog/invitation-sent-dialog.component';
import { NewUserDialogComponent } from './new-user-dialog/new-user-dialog.component';
import { RevokeInviteDialogComponent } from './revoke-invite-dialog/revoke-invite-dialog.component';
import { UsersComponent } from './users.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { ViewUserDialogComponent } from './view-user-dialog/view-user-dialog.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CdkMenuTrigger,
    CommonModule,
    FormsModule,
    FwAlertModule,
    FwAvatarModule,
    FwBadgeModule,
    FwButtonModule,
    FwChipModule,
    FwDialogsModule,
    FwIconButtonModule,
    FwIconModule,
    FwMenuModule,
    FwPaginatorModule,
    FwSectionHeadingModule,
    FwSelectMenuModule,
    FwSnackbarModule,
    FwTextInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    ReactiveFormsModule,
    TestIdDirective,
    ThemeModule,
    FwLayoutsModule,
    FwTextAreaInputModule,
  ],
  declarations: [
    DeactivateUserDialogComponent,
    EditUserDialogComponent,
    NewUserDialogComponent,
    UsersComponent,
    UsersTableComponent,
    ViewUserDialogComponent,
    RevokeInviteDialogComponent,
    InvitationSentDialogComponent,
  ],
  exports: [
    DeactivateUserDialogComponent,
    EditUserDialogComponent,
    NewUserDialogComponent,
    UsersComponent,
    UsersTableComponent,
    ViewUserDialogComponent,
  ],
})
export class UsersModule {
}
