import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FwSnackbarService, FwValidators } from '@flywheel-io/vision';

import { OrganizationsService } from '../../../services/organizations/organizations.service';

export interface DialogData {
  orgId?: string;
}

@Component({
  selector: 'app-new-organization-dialog',
  templateUrl: './new-organization-dialog.component.html',
  styleUrls: ['./new-organization-dialog.component.scss'],
})
export class NewOrganizationDialogComponent {
  insertOrgMutation;
  apiErrorMessage?: string;

  newOrganizationForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(200)]],
    label: ['', [Validators.maxLength(200)]],
    given_name: ['', [Validators.required, Validators.maxLength(64)]],
    family_name: ['', [Validators.required, Validators.maxLength(64)]],
    email: ['', [Validators.required, FwValidators.email]],
  });

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: DialogData,
  ) {
    this.insertOrgMutation = this.orgServices.injectInsertOrgMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Organization created!',
        });
        this.dialogRef.close();
      },
      onError: (err) => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during create.',
        });
        this.apiErrorMessage = err.message+'. Possibly duplicate Organization Label.';
      },
    });
  }

  handleAlertClose(): void {
    this.apiErrorMessage = undefined;
  }
  cancel(): void {
    this.dialogRef.close();
  }

  getErrorMessage(control: FormControl): string {
    const { errors, touched } = control;

    if(!errors || !touched) {
      return '';
    }
    if(errors['required']) {
      return 'This field is required';
    }
    if(errors['maxlength']) {
      return `Maximum length of ${errors['maxlength']?.requiredLength}`
    }
    if(errors['email']) {
      return 'Invalid email';
    }
    return '';
  }

  createOrganization(): void {

    this.apiErrorMessage = undefined;
    this.newOrganizationForm.markAllAsTouched();

    if (this.newOrganizationForm.valid) {
      this.insertOrgMutation.mutate({
        name: this.newOrganizationForm.controls.name.value!,
        label: this.newOrganizationForm.controls.label.value!,
        initial_user: {
          given_name: this.newOrganizationForm.controls.given_name.value!,
          family_name: this.newOrganizationForm.controls.family_name.value!,
          email: this.newOrganizationForm.controls.email.value!,
        },
      });
    }
  }
}
