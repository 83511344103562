import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
  Component,
  computed,
  HostListener,
  QueryList,
  Signal,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FwDialogService } from '@flywheel-io/vision';
import { Organization } from 'src/app/models/organization';
import { FeatureFlagService } from 'src/app/services/feature-flag/feature-flag.service';

import { getOrgSlug } from '../../guards/active-org.guard';
import { ActiveOrgService } from '../../services/active-org/active-org.service';
import { BasicOrganization, OrganizationsService } from '../../services/organizations/organizations.service';
import { PermissionService } from '../../services/permission.service';
import { SelfService } from '../../services/self/self.service';
import { ThemePreference, ThemeService } from '../theme.service';
import { ProfileDialogComponent } from './profile-dialog/profile-dialog.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @HostListener('document:click') outsideClick(): void {
    if (this._isOpen && this.triggers && this.triggers.length > 0) {
      this.triggers.forEach(trigger => {
        trigger.close();
      });
      this._isOpen = false;
    }
    if (this.triggers && this.triggers.length > 0) {
      this.triggers.forEach(trigger => {
        if (trigger.isOpen()) {
          this._isOpen = true;
        }
      });
    }
  }

  @ViewChildren(CdkMenuTrigger) triggers?: QueryList<CdkMenuTrigger>;
  activeOrgs: Signal<Organization[]>;
  private _isOpen = false;

  constructor(
    public dialog: FwDialogService,
    public selfService: SelfService,
    public flagsService: FeatureFlagService,
    private authService: AuthService,
    public activeOrgService: ActiveOrgService,
    private permissionsService: PermissionService,
    public themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationsService,
    private router: Router,
  ) {
    this.activeOrgs = computed(() => selfService.selfSignal()?.organizations.filter(org => org.status === 'active') || []);
  }

  selfSignal = this.selfService.selfSignal;
  showBilling = computed(() => this.permissionsService.check('GET_BILLING')() && this.flagsService.getFlag('billing_frontend')());

  handleThemeChange(value: string | string[]): void {
    this.themeService.setTheme(value as ThemePreference);
  }

  canAdminTenants = this.permissionsService.check('CREATE_TENANT');
  canAdminOrgs = this.permissionsService.check('CREATE_ORG');

  openUserDialog(): void {
    this.dialog.openDialog(ProfileDialogComponent);
  }

  sortedOrgs = computed(() => {

    const allOrgs = this.organizationService.allOrganizationsSignal();

    const memberOrgs = this.selfService.selfSignal()?.organizations.filter(org => org.status === 'active');

    const orgsToReturn = allOrgs || memberOrgs || [];

    const sorted = orgsToReturn?.sort((a, b) => a.name.localeCompare(b.name));

    return sorted as BasicOrganization[];
  })

  changeOrg(org?: Organization | BasicOrganization): void {
    const pathFromRoot = this.activatedRoute.snapshot.pathFromRoot;
    const fullPath = pathFromRoot.map(segment => segment.url.map(segment => segment.path)).flat();
    const pathAfterOrg = fullPath.slice(2);
    const newOrgSlug = getOrgSlug(org);

    const newPath = ['/', 'org', newOrgSlug, ...pathAfterOrg];

    this.router.navigate(newPath);
  }

  logout(): void {
    const redirectUrl = `${window.location.origin}/logged-out`;
    this.authService.logout({
      logoutParams: {
        returnTo: redirectUrl,
      },
    });
  }

  getActiveOrgSlug = computed(() => {
    const org = this.activeOrgService.org();
    return getOrgSlug(org);
  });
}
