import { CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLinkActive } from '@angular/router';
import {
  FwAlertModule,
  FwAvatarModule,
  FwBadgeModule,
  FwButtonModule,
  FwChipModule,
  FwDialogsModule,
  FwFormHeadingModule,
  FwIconButtonModule,
  FwIconModule,
  FwLayoutsModule,
  FwMenuModule,
  FwPaginatorModule,
  FwSectionHeadingModule,
  FwSelectMenuModule,
  FwSnackbarModule,
  FwTextAreaInputModule,
  FwTextInputModule,
  FwTooltipModule,
  FwWrappedInputModule,
} from '@flywheel-io/vision';

import { TestIdDirective } from '../../directives/test-id.directive';
import { ThemeModule } from '../../theme/theme.module';
import { DeactivateTenantDialogComponent } from './deactivate-tenant-dialog/deactivate-tenant-dialog.component';
import { EditTenantDialogComponent } from './edit-tenant-dialog/edit-tenant-dialog.component';
import { NewTenantDialogComponent } from './new-tenant-dialog/new-tenant-dialog.component';
import { TenantsComponent } from './tenants.component';
import { TenantTableComponent } from './tenants-table/tenant-table.component';
import { TenantsTableHeaderComponent } from './tenants-table/tenants-table-header/tenants-table-header.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CdkMenuTrigger,
    CommonModule,
    FormsModule,
    FwAlertModule,
    FwAvatarModule,
    FwBadgeModule,
    FwButtonModule,
    FwChipModule,
    FwDialogsModule,
    FwFormHeadingModule,
    FwIconButtonModule,
    FwIconModule,
    FwLayoutsModule,
    FwMenuModule,
    FwPaginatorModule,
    FwSectionHeadingModule,
    FwSelectMenuModule,
    FwSnackbarModule,
    FwTextAreaInputModule,
    FwTextInputModule,
    FwTooltipModule,
    FwWrappedInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    ReactiveFormsModule,
    RouterLinkActive,
    TestIdDirective,
    ThemeModule,
  ],
  declarations: [
    DeactivateTenantDialogComponent,
    EditTenantDialogComponent,
    NewTenantDialogComponent,
    TenantsComponent,
    TenantTableComponent,
    TenantsTableHeaderComponent,
  ],
  exports: [
    DeactivateTenantDialogComponent,
    EditTenantDialogComponent,
    NewTenantDialogComponent,
    TenantsComponent,
    TenantTableComponent,
  ],
})
export class TenantsModule {
}
