import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateMutationResult,
  CreateQueryResult,
  injectMutation,
  injectQuery,
  injectQueryClient,
  MutationOptions,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  ExpandedUser,
  User,
} from '../../models/users.types';
import { ActiveOrgService } from '../active-org/active-org.service';

interface UpdateSelfPayload {
  given_name: string;
  family_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class SelfService {
  private client;
  public selfQuery;
  public selfSignal;

  constructor(
    private http: HttpClient,
    private activeOrgService: ActiveOrgService,
  ) {
    this.client = injectQueryClient();
    this.selfQuery = this.injectSelfQuery();
    this.selfSignal = this.selfQuery.data;
  }

  /* Self */
  getSelf(): Observable<User> {
    return this.http.get<User>(`${environment.apiBaseUrl}/users/self`);
  }

  injectSelfQuery(): CreateQueryResult<ExpandedUser, Error> {
    return injectQuery(() => ({
      queryKey: ['self'],
      queryFn: () => lastValueFrom(this.getSelf()),
      select: (data) => {

        const activeOrg = this.activeOrgService.org() || data.organizations?.[0];

        return {
          ...data,
          initials: data.given_name[0] + data.family_name[0],
          fullName: `${data.given_name} ${data.family_name}`,
          role: activeOrg.friendlyRole || activeOrg.organization_role,
          organizationName: activeOrg?.name || '',
        };
      },
    }));
  }

  private updateSelf(payload: UpdateSelfPayload): Observable<User> {
    return this.http.put<User>(`${environment.apiBaseUrl}/users/self`, payload);
  }

  injectUpdateSelfMutation(options?: MutationOptions<User, HttpErrorResponse, UpdateSelfPayload>): CreateMutationResult<User, HttpErrorResponse, UpdateSelfPayload> {
    return injectMutation(() => ({
      ...options,
      mutationFn: (payload: UpdateSelfPayload) => {
        return lastValueFrom(this.updateSelf(payload));
      },
      onSuccess: (data, variable, context) => {
        this.client.invalidateQueries({ queryKey: ['self'] });
        this.client.invalidateQueries({ queryKey: ['users'] });
        options?.onSuccess?.(data, variable, context);
      },
    }));
  }
}
