import { Component, computed, signal, WritableSignal } from '@angular/core';

import { FeedItem, FeedStreamRequestOptions } from '../../../models/stream.types';
import { FeedStreamsService } from '../../../services/feed/streams.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  feedData: FeedItem[] = [];
  options: WritableSignal<FeedStreamRequestOptions> = signal({
    sort: 'published_at',
  });

  constructor(
    private feedStreamsService: FeedStreamsService,
  ) {}

  feedQuery = this.feedStreamsService.injectFeedStreamItemsQuery(this.options);

  newsToDisplay = computed(() => {
    const newsItems = this.feedQuery.data()?.data.toReversed().slice(0, 4);
    return newsItems || [];
  })

  openUrl(url: string): void {
    window.open(url);
  }
}
