import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, effect, Inject, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FwSnackbarService } from '@flywheel-io/vision';
import { CreateQueryResult } from '@tanstack/angular-query-experimental';

import { ReleaseChannels } from '../../../models/release-channels';
import { OrganizationsService } from '../../../services/organizations/organizations.service';

export interface NewDialogData {
  orgId?: string;
}

@Component({
  selector: 'app-new-tenant-dialog',
  templateUrl: './new-tenant-dialog.component.html',
  styleUrls: ['./new-tenant-dialog.component.scss'],
})
export class NewTenantDialogComponent {
  orgReleaseChannelsQuery: CreateQueryResult<ReleaseChannels[]>;
  releaseChannels: ReleaseChannels[] = [];
  insertOrgTenantMutation;
  errorMessage?: string;
  orgIdSignal: WritableSignal<string>;
  busy: boolean = false;

  newTenantForm = this.fb.group({
    org_id: new FormControl(this.data?.orgId || '', [Validators.required]),
    release_channel: new FormControl('', [Validators.required]),
    label: new FormControl('', [Validators.required, Validators.max(127)]),
    notes: new FormControl('', [Validators.max(1000)]),
  });

  constructor(
    private orgServices: OrganizationsService,
    private fb: FormBuilder,
    public snackbarService: FwSnackbarService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data?: NewDialogData,
  ) {
    this.orgIdSignal = signal(this.data!.orgId!)
    this.orgReleaseChannelsQuery = this.orgServices.injectOrgReleaseChannelsQuery(this.orgIdSignal);
    this.insertOrgTenantMutation = this.orgServices.injectInsertOrgTenantMutation({
      onSuccess: () => {
        this.snackbarService.show({
          severity: 'success',
          message: 'Tenant created!',
        });
        this.busy=false;
        this.dialogRef.close();
      },
      onError: (err) => {
        this.snackbarService.show({
          severity: 'error',
          message: 'Something went wrong during create.',
        });
        this.errorMessage = err.message+'. Possibly duplicate Tenant Label.';
        this.busy=false;
      },
    });

    effect(() => {
      const fetchedChannels = this.orgReleaseChannelsQuery.data();

      if (fetchedChannels) {
        const sortedChannels = fetchedChannels.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          return nameA < nameB ? -1 : 1;
        });
        this.releaseChannels = sortedChannels;
      }
    });

    this.newTenantForm.valueChanges.subscribe(value=>{
      this.orgIdSignal.set(value.org_id!);
    });
  }

  handleAlertClose(): void {
    this.errorMessage = undefined;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  createTenant(): void {
    this.newTenantForm.markAllAsTouched();
    if (this.newTenantForm.valid) {
      this.busy=true;
      this.insertOrgTenantMutation.mutate({
        org_id: this.newTenantForm.controls.org_id.value!,
        release_channel: this.newTenantForm.controls.release_channel.value!,
        label: this.newTenantForm.controls.label.value!,
        notes: this.newTenantForm.controls.notes!.value!,
      });
    }
  }
}
