<fw-dialog width="small" [showClose]="true">
  <fw-dialog-header>
      <fw-icon class="header-icon">close-circled</fw-icon>
      <h3>Deactivate User</h3>
  </fw-dialog-header>

  <fw-dialog-content>
    <p>
      Are you sure you want to deactivate
      <strong>{{fullName}}</strong>?
      Once deactivated, the user will no longer
      be able to sign in and all their permissions will be revoked.
      This action can always be undone by reactivating the user.
    </p>
  </fw-dialog-content>

  <fw-dialog-actions>
    <fw-button (click)="close()"
               variant="outline"
               color="primary"
               leftIcon="arrow-back"
               [testId]="'btn-deactivate-user-back'">
      Back
    </fw-button>
    <div class="flex-space"></div>
    <fw-button (click)="close()"
               variant="outline"
               color="danger"
               [testId]="'btn-deactivate-user-cancel'">
      Cancel
    </fw-button>
    <fw-button (click)="handleDeactivate()"
               [disabled]="updateUserMutation.isPending()"
               *ngIf="status==='active' && canDeactivateUsers() && authService.currentUser?.id !== userId"
               variant="solid"
               color="danger"
               [testId]="'btn-edit-user-deactivate'">
      Deactivate User
    </fw-button>
  </fw-dialog-actions>
</fw-dialog>
